import moment from "moment";
import "moment/locale/th";

const defaultExport = {
  now: () => Date.now(),
  new: (ts) => moment(ts),
  displayAs: (obj, format) => {
    const lang = localStorage.getItem("i18nextLng");
    const value = obj ? obj : moment();
    if (moment.isMoment(value)) return value.locale(lang).format(format);
    if (value instanceof Date)
      return moment(value.getTime()).locale(lang).format(format);
    if (typeof value === "number")
      return moment(value).locale(lang).format(format);
  },
};
export default defaultExport;
