import { useEffect, useState, useContext } from "react";
import { Navigate } from "react-router";
import { useLocation } from "react-router-dom";
import { ROUTE } from "../../config/common";
import { checkLogin } from "../../utilities/authenUtil";
import { TOTAL_SCREENINGS } from "../../graphql/query";
import { useQuery } from "@apollo/client";
import {
  CommonContext,
  CommonDispatchContext,
} from "../../stateManagement/context/commonContext";
import { ACTIONS } from "../../stateManagement/actions/commonAction";

const ProtectedRoute = ({ children }) => {
  const { isLogin, role, errorMessage } = checkLogin();
  const dispatch = useContext(CommonDispatchContext);
  const { globalLoading } = useContext(CommonContext);

  const [mount, setMount] = useState(false);
  const { pathname } = useLocation();
  const { loading, error, data, refetch } = useQuery(TOTAL_SCREENINGS, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (isLogin && globalLoading && dispatch) {
      dispatch({
        type: ACTIONS.HIDE_GLOBAL_LOADING,
      });
    }
  }, [isLogin, globalLoading, dispatch]);

  // When mounted fetch totalQuestion
  useEffect(() => {
    const isLoggedIn = isLogin;
    if (isLoggedIn) {
      refetch();
    }
    setMount(true);
  }, []);
  // End when mounted.

  const redirectPath = process.env.REACT_APP_LANDING_PAGE
    ? `${process.env.REACT_APP_LANDING_PAGE}&error=${
        errorMessage || "unauthen"
      }&redirect=${window?.location?.href || ""}`
    : ROUTE.UNAUTHEN;

  const redirectElement =
    !isLogin &&
    (redirectPath === ROUTE.UNAUTHEN ? (
      <Navigate to={redirectPath} replace />
    ) : (
      window.location.replace(redirectPath)
    ));

  // redirect with role
  if (isLogin && role !== "PARTICIPANT") {
    if (role === "RESEARCHER" && process.env.REACT_APP_RESEARCHER_PATH) {
      window.location.replace(process.env.REACT_APP_RESEARCHER_PATH);
    } else {
      // case no path in env
      return <Navigate to={ROUTE.UNAUTHEN} replace />;
    }
  }
  const { totalScreenings } = data || {};
  const [accumulateAnswer, accumulateTotal] = (
    (totalScreenings &&
      totalScreenings.filter(
        (item) => item.typeName !== "Partner Organization"
      )) ||
    []
  ).reduce(
    (acc, cur) => {
      return [acc[0] + cur?.totalAnswer || 0, acc[1] + cur?.totalQuestion || 0];
    },
    [0, 0]
  );
  const redirectFirstTake = accumulateAnswer !== accumulateTotal;
  if (loading) return "Loading...";

  return mount ? (
    isLogin ? (
      redirectFirstTake && pathname !== ROUTE.FIRST_TAKE_SURVEY ? (
        <Navigate to={ROUTE.FIRST_TAKE_SURVEY} replace />
      ) : (
        children
      )
    ) : (
      redirectElement
    )
  ) : null;
};

export default ProtectedRoute;
