import { useContext, useEffect, lazy, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Spin } from "antd";
// import config
import { ROUTE } from "./config/common";

// import screen

import {
  AccountMobileHomeScreen,
  AccountMobilePernalDataScreen,
} from "./pages/accountScreen";
import UnAuthenScreen from "./pages/unAuthenScreen";
// import component
import ChangeLang from "./components/common/ChangeLang";
import MyEarning from "./components/account/MyEarning";
import CashDetail from "./components/account/CashDetail";
import Navbar from "./components/common/Navbar";
import CustomModal from "./components/common/CustomModal";
import ChangePassword from "./components/account/ChangePassword";
import LinkBankAccount from "./components/account/LinkBankAccount";
// import style
import "./styles/App.css";
import "./styles/theme.css";
import "antd/dist/antd.min.css";
import CashOut from "./components/account/CashOut";
import CashOutHistory from "./components/account/CashOutHistory";
import ProtectedRoute from "./components/common/ProtectedRoute";
import {
  CommonContext,
  CommonDispatchContext,
} from "./stateManagement/context/commonContext";
import { ACTIONS } from "./stateManagement/actions/commonAction";
import { matchPath } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

// login hanler
import { checkLogin } from "./utilities/authenUtil";
import { useLazyQuery } from "@apollo/client";
import { PROFILE } from "./graphql/query";
// Language cookie import
import Cookies from "js-cookie";
import psl from "psl";
import { isLocalhost } from "./utilities/authenUtil";
import { useTranslation } from "react-i18next";

const FirstTakeSurveyScreen = lazy(() => import("./pages/firstTakeSurvey"));
const StudiesScreen = lazy(() => import("./pages/studiesScreen"));
const RewardsScreen = lazy(() => import("./pages/rewardsScreen"));
const SubmissionScreen = lazy(() => import("./pages/submissionsScreen"));
const RedemptionScreen = lazy(() => import("./pages/redemptionScreen"));
const ScreeningQuestionScreen = lazy(() =>
  import("./pages/screeningQuestionScreen")
);
const EditScreeningQuestion = lazy(() =>
  import("./pages/editScreeningQuestion")
);
const AccountScreen = lazy(() => import("./pages/accountScreen"));

function App() {
  const { i18n } = useTranslation();
  const dispatch = useContext(CommonDispatchContext);
  const { globalLoading } = useContext(CommonContext);
  const [getProfile] = useLazyQuery(PROFILE, {
    onCompleted: (data) => {
      const lang = data?.profile?.language === "THAI" ? "th" : "en";
      i18n.changeLanguage(lang);
      const currentLang = Cookies.get("lang");
      if (lang !== currentLang) {
        const domain = psl.get(window.location.hostname);
        Cookies.set("lang", lang, {
          domain: !isLocalhost ? `.${domain}` : "",
        });
      }
      dispatch({
        type: ACTIONS.SET_CURRENT_USER,
        currentUser: {
          ...data?.profile,
          fullname: `${
            data?.profile?.firstname ? data?.profile?.firstname : ""
          } ${data?.profile?.middlename ? data?.profile?.middlename : ""} ${
            data?.profile?.lastname ? data?.profile?.lastname : ""
          }`,
        },
      });
    },
  });
  const { pathname } = useLocation();
  const { isLogin: loggedIn } = checkLogin();
  useEffect(() => {
    if (loggedIn && pathname !== ROUTE.UNAUTHEN) {
      getProfile();
    }
  }, [getProfile, loggedIn, pathname]);

  const hideNavRouteList = [ROUTE.FIRST_TAKE_SURVEY, ROUTE.UNAUTHEN];
  const hideGlobalLoadRouteList = [ROUTE.UNAUTHEN];
  const splitPathname = pathname.split("/").filter((a) => a);

  return (
    <div className="App">
      {globalLoading &&
        !hideGlobalLoadRouteList.some((c) =>
          matchPath({ path: c }, pathname)
        ) && (
          <div id="global-loading">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} />} />
          </div>
        )}
      <CustomModal />
      {hideNavRouteList.some((c) => pathname.includes(c)) ? null : <Navbar />}
      <div
        id="content-container"
        className={splitPathname.map((name) => `${name}-screen`).join(" ")}
      >
        <Suspense
          fallback={
            <div className="w-full h-full flex items-center justify-center">
              <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} />} />
            </div>
          }
        >
          <Routes>
            <Route
              path={ROUTE.ROOT}
              element={
                <ProtectedRoute>
                  <StudiesScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTE.REWARDS}
              element={
                <ProtectedRoute>
                  <RewardsScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTE.REDEMPTION}
              element={
                <ProtectedRoute>
                  <RedemptionScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTE.SUBMISSIONS}
              element={
                <ProtectedRoute>
                  <SubmissionScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTE.SCREENING_QUESTION}
              element={
                <ProtectedRoute>
                  <ScreeningQuestionScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTE.ACCOUNT.ROOT}
              element={
                <ProtectedRoute>
                  <AccountScreen />
                </ProtectedRoute>
              }
            >
              <Route
                path={ROUTE.ACCOUNT.MAIN_PAGE}
                element={<AccountMobileHomeScreen />}
              />
              <Route
                path={ROUTE.ACCOUNT.PERSONAL_DATA}
                element={<AccountMobilePernalDataScreen />}
              />
              <Route
                path={ROUTE.ACCOUNT.PASSWORD}
                element={<ChangePassword />}
              />
              <Route
                path={ROUTE.ACCOUNT.CASH_DETAIL.ROOT}
                element={<CashDetail />}
              />
              <Route path={ROUTE.ACCOUNT.MY_EARNING} element={<MyEarning />} />
              <Route path={ROUTE.ACCOUNT.LANGUAGE} element={<ChangeLang />} />
              <Route
                path={ROUTE.ACCOUNT.CASH_DETAIL.LINK_BANK_ACCOUNT}
                element={<LinkBankAccount />}
              />
              <Route
                path={ROUTE.ACCOUNT.CASH_DETAIL.EDIT_VERIFY_ACCOUNT}
                element={<LinkBankAccount />}
              />
              <Route
                path={ROUTE.ACCOUNT.CASH_DETAIL.CASH_OUT_HISTORY}
                element={<CashOutHistory />}
              />
              <Route
                path={ROUTE.ACCOUNT.CASH_DETAIL.CASH_OUT}
                element={<CashOut />}
              />
            </Route>
            <Route
              path={ROUTE.FIRST_TAKE_SURVEY}
              element={
                <ProtectedRoute>
                  <FirstTakeSurveyScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTE.SCREENING_QUESTION}/:sectionID`}
              element={
                <ProtectedRoute>
                  <EditScreeningQuestion />
                </ProtectedRoute>
              }
            />
            <Route path={ROUTE.UNAUTHEN} element={<UnAuthenScreen />} />
          </Routes>
        </Suspense>
      </div>
    </div>
  );
}

export default App;
