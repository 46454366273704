import React from "react";
import { FileUploader } from "react-drag-drop-files";
// import { Upload } from 'antd';
import { useTranslation } from "react-i18next";
import { notEmpty, isString } from "../../utilities/util";
import i18n from "i18next";
import "./DragAndDropWrapper.css";
// const { Dragger } = Upload;

const DragAndDropWrapper = ({
  wrapClassName,
  placeholder,
  haveFile,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const { format: f } = i18n;
  const dropzoneElement = notEmpty(haveFile) ? (
    <div className="upload-file-detail-container cursor-pointer text-center flex-col items-center flex">
      <div className="file-detail-container">
        <img src="/icon/file-detail.svg" alt="file-detail" />
      </div>
      <div className="file-name-label">
        {isString(haveFile) ? haveFile : haveFile.name}
      </div>
      <div id="reupload-label">
        {t("new-upload-notify")}
        <span className="click-text-button">{f(t("click"), "capitalize")}</span>
        {t("to-suffix-notify")}
      </div>
    </div>
  ) : (
    <div className="drag-zone-container">
      <div className="upload-button-container">
        <img src="/icon/upload-icon.svg" alt="upload-icon" />
      </div>
      <div className="upload-label">
        {t(placeholder || "drag-or-click-book-bank-label")}
      </div>
    </div>
  );
  return (
    <div className={wrapClassName}>
      <FileUploader children={dropzoneElement} {...otherProps} />
    </div>
  );
};

export default DragAndDropWrapper;
