// for gql query config// for gql query config
import { gql } from "@apollo/client";
const INTERFACE = {
  ScreeningType: `uid
      typeName
      order
      createdBy
      createdAt
      updatedBy
      updatedAt
      question {
        uid
        questionTitle
        questionSubject
        answerColumn
        answerForm
        multiAnswer
        order
        createdBy
        createdAt
        updatedBy
        updatedAt
        choice {
          uid
          choiceValue
          specifyAnswer
          order
          checked
          createdBy
          createdAt
          updatedBy
          updatedAt
        }
        specify {
          value
        }
      }`,
};

const LOGOUT = gql`
  query logout {
    logout {
      messageCode
      message
    }
  }
`;

const PROFILE = gql`
  query getProfile {
    profile {
      uid
      email
      profilePic
      firstname
      middlename
      lastname
      dateOfBirth
      language
      role
      language
      currentBalance
      currentCredit
      verifyEmail
      nationality
      gender
      address
      identificationNumber
      phoneNumber
      overAge
      acceptConditions
      createdBy
      createdAt
      updatedBy
      updatedAt
      changeEmailGeneratedAt
      changeEmailPending
      changeEmailStatus
      changeEmailVerifyKey
    }
  }
`;

const TOTAL_SCREENINGS = gql`
  query getTotalScreening {
    totalScreenings {
      ${["uid", "typeName", "totalAnswer", "totalQuestion"].join("\n")}
    }
  }
`;

const LIST_SCREENING_QUESTION = gql`
  query listScreening {
    screenings(orderBy: {order: order, sort: orderasc}) {
      ${INTERFACE.ScreeningType}
    }
  }
`;

const LIST_SCREENING_QUESTION_BY_TYPE = gql`
  query screeningByType($typeUid: String) {
    screenings(typeUid: $typeUid, orderBy: {order: order, sort: orderasc}) {
      ${INTERFACE.ScreeningType}
    }
  }
`;

// api for studies screen
const AVAILABLE_SURVEY = gql`
  query availableProjects {
    availableProjects {
      projectUid
      surveyType
      name
      description
      device
      totalParticipantLimit
      totalSubmission
      estimatedDuration
      costPerParticipant
      additionalIntensives
      firstname
      lastname
      currentCube
      submissionStatus
      studyUid
      paymentType
    }
  }
`;

// submission history
const LIST_SUBMISSION_HISTORY = gql`
  query listSubmissionHistory($offset: Int, $limit: Int) {
    submissionHistory(offset: $offset, limit: $limit) {
      totalPage
      totalRecord
      currentPage
      limit
      data {
        submissionUid
        submissionStatus
        createdAt
        earning
        projectName
        paymentType
        firstname
        lastname
        surveyType
      }
    }
  }
`;

const GET_SUBMISSION_DETAIL = gql`
  query submissionDetail {
    submissionDetail {
      totalCompletion
      timeout
      quit
      tooQuick
      tooSlow
    }
  }
`;

const GET_TOTAL_EARNING = gql`
  query submissionTotalEarning {
    submissionTotalEarning {
      totalCreditEarning
      totalEarning
      updatedAt
      updatedCreditAt
    }
  }
`;

const GET_BANK_VERIFICATION_BY_USER = gql`
  query bankVerificationByUser {
    bankVerificationByUser {
      uid
      bankNo
      holderName
      bookBankImageFullPath
      idCardImageFullPath
      bookBankImagePath
      idCardImagePath
      bookBankImageName
      idCardImageName
      provider {
        uid
        bankShortName
        bankOfficialName
        bankNiceNameEN
        bankNiceNameTH
        bankColor
        swiftCode
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
      verifyStatus
      createdAt
      createdBy
      updatedBy
      updatedAt
    }
  }
`;

const LIST_BANK_PROVIDERS = gql`
  query bankProviders($lang: String!) {
    bankProviders(lang: $lang) {
      uid
      bankShortName
      bankOfficialName
      bankNiceNameEN
      bankNiceNameTH
      bankColor
      swiftCode
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
  }
`;

const WITHDRAW_TRANSACTION_LIST = gql`
  query WithdrawTransaction($offset: Int, $limit: Int) {
    withdrawTransaction(offset: $offset, limit: $limit) {
      totalPage
      totalRecord
      currentPage
      limit
      totalPage
      data {
        uid
        transactionName
        transactionWHT
        transactionFee
        transactionType
        disapproveMessage
        transactionAmount
        transactionAmountSummary
        transactionStatus
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
    }
  }
`;

const GET_MY_EARNING = gql`
  query myEarning {
    myEarning {
      name
      icredit
      baht
    }
  }
`;

const GET_NOTIFICATION = gql`
  query getNotification($offset: Int, $limit: Int) {
    notifications(offset: $offset, limit: $limit) {
      totalRecord
      currentPage
      limit
      totalPage
      totalUnread
      data {
        notificationUid
        notificationProcess
        notificationPath
        messageEN
        messageTH
        flagRead
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
    }
  }
`;
const GET_REWARD = gql`
  query reward($id: String!) {
    reward(id: $id) {
      uid
      name
      description
      numberRedeem
      numberPrizes
      limitPerson
      status
      receiptDescription
      expirationDate
      code
      rewardFiles {
        fileName
        filePath
      }
      redeems {
        uid
        createdBy
        updatedBy
        account {
          email
          firstname
          lastname
          role
        }
        status
      }
      account {
        uid
        firstname
        lastname
        email
        role
      }
    }
  }
`;
const FILTER_PARTNER_ORGANIZATION = gql`
  query SearchPartnerOrganization($keyword: String) {
    searchPartnerOrganization(keyword: $keyword) {
      choiceUid
      choiceValue
    }
  }
`;
const SEARCH_CODE_REWARD = gql`
  query SearchCodeReward($code: String!) {
    searchCodeReward(code: $code) {
      uid
      name
      code
      description
      numberRedeem
      numberPrizes
      limitPerson
      rewardFiles {
        fileName
        filePath
      }
      status
      expirationDate
      receiptDescription
      createdBy
      createdAt
      updatedBy
      updatedAt
      redeems {
        account {
          uid
        }
        status
        createdAt
        createdBy
        uid
      }
      expirationDateUnlimited
      numberPrizeUnlimited
      limitPersonUnlimited
      account {
        uid
        firstname
        lastname
        email
      }
    }
  }
`;
const GET_REDEEM_HISTORY = gql`
  query RedeemsByAccount($offset: Int, $limit: Int) {
    redeemsByAccount(offset: $offset, limit: $limit) {
      totalRecord
      currentPage
      limit
      totalPage
      data {
        account {
          uid
          email
          firstname
          lastname
          role
        }
        code
        createdAt
        createdBy
        reward {
          uid
          name
          code
          description
          numberRedeem
          numberPrizes
          limitPerson
          account {
            firstname
            lastname
            email
          }
          status
          expirationDate
        }
        uid
        status
        updatedAt
        updatedBy
      }
    }
  }
`;

export {
  LOGOUT,
  PROFILE,
  AVAILABLE_SURVEY,
  LIST_SUBMISSION_HISTORY,
  TOTAL_SCREENINGS,
  LIST_SCREENING_QUESTION,
  LIST_SCREENING_QUESTION_BY_TYPE,
  GET_SUBMISSION_DETAIL,
  GET_TOTAL_EARNING,
  GET_BANK_VERIFICATION_BY_USER,
  LIST_BANK_PROVIDERS,
  WITHDRAW_TRANSACTION_LIST,
  GET_MY_EARNING,
  GET_NOTIFICATION,
  GET_REWARD,
  FILTER_PARTNER_ORGANIZATION,
  SEARCH_CODE_REWARD,
  GET_REDEEM_HISTORY,
};
