import { Responsive } from "../../utilities/Responsive/responsive";
import { DEVICE } from "../../config/common";
import { useTranslation } from "react-i18next";
// import style 
import './PersonalDataNotice.css';

const PersonalDataNotice = () => {
  const { t: getText, i18n } = useTranslation();
  return (
    <>
      <Responsive displayIn={[DEVICE.LAPTOP]}>
        <div className="personal-data-notice-container desktop">
          <img className="shield-icon" src="/images/account/shield-icon.svg" alt="" />
          <div className="notice-data-text">{getText('notice-keep-all-information')}</div>
        </div>
      </Responsive>
      <Responsive displayIn={[DEVICE.TABLET, DEVICE.MOBILE]} >
        <div className="personal-data-notice-container mobile">
          <div className="notice-data-text">{getText('notice-keep-all-information')}</div>
          <img className="shield-icon" src="/images/account/shield-icon.svg" alt="" />
        </div>
      </Responsive>
    </>
  );
}


export default PersonalDataNotice;