import { notification } from "antd";
import i18n from "../i18n";
const mapInterfaceMethod = {
  ...notification,
  success: (payload = {}) => {
    const translatedMessage = i18n.t(payload?.message) || "";
    notification.success({ ...payload, message: translatedMessage });
  },
  error: (payload = {}) => {
    const translatedMessage = i18n.t(payload?.message) || "";
    notification.error({ ...payload, message: translatedMessage });
  },
};

export default mapInterfaceMethod;
