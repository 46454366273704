import { useContext, useEffect, useState } from "react";
// import useCopy from "use-copy";
import { Avatar } from "antd";
import notification from "../../services/notification";
// import compoent
import { Responsive } from "../../utilities/Responsive/responsive";
// import style
import "./UserDetail.css";
// import config
import { DEVICE } from "../../config/common";
import { useTranslation } from "react-i18next";
import Upload from "antd/lib/upload/Upload";
import { useMutation } from "@apollo/client";
import { UPLOAD_PROFILE_IMAGE } from "../../graphql/mutation";
import { CommonContext } from "../../stateManagement/context/commonContext";
import { PROFILE } from "../../graphql/query";
import { CopyToClipboard } from "react-copy-to-clipboard";

const UserDetail = ({ uid = "", title, role }) => {
  const { currentUser } = useContext(CommonContext);
  const [copied, copy] = useState(false);
  const { t: getText, i18n } = useTranslation();
  const [uploadProfileImage] = useMutation(UPLOAD_PROFILE_IMAGE, {
    refetchQueries: [{ query: PROFILE }],
    onCompleted: (data) => {
      notification.success({ message: data?.uploadProfilePic?.message });
    },
  });
  useEffect(() => {
    if (copied) {
      notification.success({
        message: i18n.format(getText("copied-uid"), "capitalize"),
      });
    }
  }, [copied]);

  const handleUpload = async (data) => {
    uploadProfileImage({
      variables: {
        profileImage: data?.file,
      },
    });
  };

  const avatarItem = (
    <div id="user-detail-avatar-wrapper">
      <Upload
        className="upload-avatar-button"
        showUploadList={false}
        customRequest={handleUpload}
      >
        {i18n.format(getText("edit"), "capitalize")}
      </Upload>
      <Avatar
        id="user-detail-avatar-container"
        size={65}
        src={
          <img
            id={!currentUser?.profilePic ? "icon-grey-scale" : ""}
            src={
              currentUser?.profilePic ||
              "/images/common/informata-grey-icon.svg"
            }
            alt=""
          />
        }
      />
    </div>
  );

  return (
    <div>
      <Responsive displayIn={[DEVICE.TABLET, DEVICE.MOBILE]}>
        <div className="user-detail-container mobile">
          {avatarItem}
          <div id="user-detail-title">{title}</div>
          <div id="user-detail-role">
            {i18n.format(getText(`role.${role.toLowerCase()}`), "capitalize")}
          </div>
          <div id="copy-uid-container">
            <div className="flex items-center h-full overflow-hidden">
              <div id="id-label">ID:</div>
              <div id="uid-text">{uid}</div>
            </div>
            <CopyToClipboard text={uid} onCopy={() => copy(true)}>
              <div id="copy-icon" />
            </CopyToClipboard>
          </div>
        </div>
      </Responsive>
      <Responsive displayIn={[DEVICE.LAPTOP]}>
        <div className="user-detail-container desktop">
          {avatarItem}
          <div id="user-detail-title">{title}</div>
          <div id="user-detail-role">
            {i18n.format(getText(`role.${role.toLowerCase()}`), "capitalize")}
          </div>
          <div id="copy-uid-container">
            <div id="uid-text">{uid}</div>
            {/* <div id="copy-icon" onClick={() => copy()} /> */}
            <CopyToClipboard text={uid} onCopy={() => copy(true)}>
              <div id="copy-icon" />
            </CopyToClipboard>
          </div>
        </div>
      </Responsive>
    </div>
  );
};

export default UserDetail;
