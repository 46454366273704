import { Space, Avatar, Dropdown, Menu, Spin } from "antd";
import { CaretDownFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { getFlag, isFunction } from "../../utilities/util";
import "./ChangeLang.css";
import { useMutation } from "@apollo/client";
import { UPDATE_LANGUAGE } from "../../graphql/mutation";
import { PROFILE } from "../../graphql/query";
import Cookies from "js-cookie";
import psl from "psl";
import { isLocalhost } from "../../utilities/authenUtil";

const ChangeLanguage = ({ setLanguage }) => {
  const [updateLanguage, { loading }] = useMutation(UPDATE_LANGUAGE, {
    refetchQueries: [
      {
        query: PROFILE,
      },
    ],
  });

  const { t: getText, i18n } = useTranslation();
  const getCountryItem = (country, { showSelected = true }) => (
    <div
      className="pointer w-full change-language-item"
      // onClick={
      //   !showSelected
      //     ? null
      //     : () => {
      //         updateLanguage({
      //           variables: {
      //             input: { language: country === "th" ? "THAI" : "ENG" },
      //           },
      //         });
      //       }
      // }
    >
      <Space>
        <Avatar
          size={20}
          alt={country}
          src={`/images/common/icon-${country}.svg`}
          // src={getFlag(country === "en" ? "gb" : country)}
        />
        <div className="lang-label">{getText(`${country}-language`)}</div>
      </Space>
      {showSelected && i18n.language === country && (
        <img
          width={15}
          height={11}
          src="/images/common/check-icon.svg"
          alt=""
        />
      )}
    </div>
  );

  const items = [
    {
      key: "th",
      label: <span>{getCountryItem("th", { showSelected: true })}</span>,
    },
    {
      key: "en",
      label: <span>{getCountryItem("en", { showSelected: true })}</span>,
    },
  ];

  const onClick = ({ key: lang }) => {
    updateLanguage({
      variables: {
        input: { language: lang === "th" ? "THAI" : "ENG" },
      },
    });
    i18n.changeLanguage(lang);
    const currentLang = Cookies.get("lang");
    if (lang !== currentLang) {
      const domain = psl.get(window.location.hostname);
      Cookies.set("lang", lang, {
        domain: !isLocalhost ? `.${domain}` : "",
      });
    }

    if(isFunction(setLanguage)) {
      setLanguage(lang);
    }
  };



  return (
    <div id="change-language-wrapper">
      <Spin spinning={loading}>
        <div id="your-setting-label" className="font-bold">
          {i18n.format(getText("your-settings"), "capitalFirstEach")}
        </div>
        <div id="display-language-label" className="font-bold">
          {i18n.format(getText("display-language"), "capitalFirstEach")}
        </div>
        <Dropdown
          id="change-language-container"
          overlayClassName="language-options-list"
          className="w-full"
          trigger="click"
          menu={{ items, onClick }}
        >
          <div id="selected-language-container">
            {getCountryItem(i18n.language, { showSelected: false })}
            <CaretDownFilled />
          </div>
        </Dropdown>
      </Spin>
    </div>
  );
};
export default ChangeLanguage;
