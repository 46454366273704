import { useMutation } from "@apollo/client";
import { Input, Button, Form, Space, Spin } from "antd";
import notification from "../../services/notification";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../config/common";
import { PASSWORD_REGEX } from "../../config/common";
import { CHANGE_PASSWORD } from "../../graphql/mutation";
import { getRequiredText } from "../../utilities/textUtil";
import { isEmpty, notEmpty } from "../../utilities/util";
import "./ChangePassword.css";

const ChangePassword = () => {
  const { t: getText, i18n } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [changePassword, { loading }] = useMutation(CHANGE_PASSWORD, {
    onCompleted: (data) => {
      notification.success({ message: data?.changePassword?.message });
      navigate(`/${ROUTE.ACCOUNT.ROOT}`, { replace: true });
    },
  });
  const onSubmitPassword = (value) => {
    const { password: oldPassword, newPassword } = value;
    changePassword({ variables: { input: { oldPassword, newPassword } } });
  };

  const onPasswordChange = (e) => {
    const currentValue = e?.target?.value;
    const confirmValue = form.getFieldValue("confirmNewPassword");
    const { errors } = form.getFieldsError(["confirmNewPassword"])?.[0] || {};
    if (notEmpty(errors) && confirmValue && currentValue === confirmValue) {
      form.setFields([
        {
          name: "confirmNewPassword",
          errors: [],
        },
      ]);
    } else if (isEmpty(errors) && confirmValue) {
      form.setFields([
        {
          name: "confirmNewPassword",
          errors: [getText("password-confirm-not-match")],
        },
      ]);
    }
  };

  return (
    <div id="change-password-container">
      <Spin spinning={loading}>
        <div id="change-password-title">
          {i18n.format(getText("change-password"), "capitalFirstEach")}
        </div>
        <div id="change-password-description">
          {i18n.format(
            getText("change-password-description"),
            "capitalFirstEach"
          )}
        </div>
        <Form
          id="forgot-password-form"
          form={form}
          onFinish={onSubmitPassword}
          layout="horizontal"
        >
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: getRequiredText(getText, i18n, "current-password"),
              },
            ]}
          >
            <Input.Password
              placeholder={i18n.format(
                getText("current-password"),
                "capitalFirstEach"
              )}
              visibilityToggle={false}
            />
          </Form.Item>
          <Form.Item
            name="newPassword"
            rules={[
              {
                required: true,
                message: getRequiredText(getText, i18n, "new-password"),
              },
            ]}
          >
            <Input.Password
              autoComplete="new-password"
              placeholder={i18n.format(
                getText("new-password"),
                "capitalFirstEach"
              )}
              onChange={onPasswordChange}
              prefix={<img src="/images/common/lock-icon.svg" alt="" />}
              iconRender={(visible) => (
                <div className="show-password-button">
                  {i18n.format(getText("show"), "capitalFirstEach")}
                </div>
              )}
            />
          </Form.Item>
          <Form.Item
            name="confirmNewPassword"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.reject(
                      new Error(
                        getRequiredText(getText, i18n, "retype-new-password")
                      )
                    );
                  }
                  if (!PASSWORD_REGEX.test(value)) {
                    return Promise.reject(
                      new Error(getText("password-wrong-format"))
                    );
                  }
                  if (getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(getText("password-confirm-not-match"))
                  );
                },
              }),
            ]}
          >
            <Input.Password
              autoComplete="newPassword"
              placeholder={i18n.format(
                getText("retype-new-password"),
                "capitalize"
              )}
              prefix={<img src="/images/common/lock-icon.svg" alt="" />}
              iconRender={(visible) => (
                <div className="show-password-button">
                  {i18n.format(getText("show"), "capitalFirstEach")}
                </div>
              )}
            />
          </Form.Item>
        </Form>
        <Space
          id="change-password-button-container"
          className="w-full"
          direction="vertical"
        >
          <Button
            id="update-password-button"
            className="w-full"
            type="primary"
            onClick={() => form.submit()}
          >
            {i18n.format(getText("update-password"), "capitalFirstEach")}
          </Button>
          <Button
            id="cancel-button"
            className="w-full"
            onClick={() => {
              navigate(`/${ROUTE.ACCOUNT.ROOT}`, { replace: true });
            }}
          >
            {i18n.format(getText("cancel"), "capitalFirstEach")}
          </Button>
          <Button id="forgot-password-button" className="w-full" type="link">
            {`${i18n.format(
              getText("forgotten-password"),
              "capitalFirstEach"
            )}?`}
          </Button>
        </Space>
      </Spin>
    </div>
  );
};

export default ChangePassword;
