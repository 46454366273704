const answerQuestion = (state, action) => {
  return {
    ...state,
    [action.questionID]: action.answer,
  };
};

const removeOtherOption = (state, action) => {
  const { questionID } = action;
  return {
    ...state,
    [questionID]: (state?.[questionID] || []).filter(
      (o) => !(o?.id === action.answer.id)
    ),
  };
};
const appendAnswer = (state, action) => {
  const { questionID } = action;
  return {
    ...state,
    [questionID]: [...(state?.[questionID] || []), action.answer],
  };
};

const deselectAnswer = (state, action) => {
  const { questionID } = action;
  return {
    ...state,
    [questionID]: (state?.[questionID] || []).filter(
      (s) => s !== action.answer || s?.id
    ),
  };
};

const replaceValue = (state, action) => {
  const { questionID } = action;
  let found = false;
  const list = state?.[questionID] || [];
  return {
    ...state,
    [questionID]:
      list.length === 0
        ? [action.answer]
        : (state?.[questionID] || []).reduce((acc, cur, index, origArr) => {
            if (cur?.id === action.answer.id) {
              found = true;
              return [...acc, action.answer];
            }
            if (index === origArr.length - 1 && !found)
              return [...acc, cur, action.answer];
            return [...acc, cur];
          }, []),
  };
};

export {
  replaceValue,
  answerQuestion,
  removeOtherOption,
  appendAnswer,
  deselectAnswer,
};
