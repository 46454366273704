import { createContext, useReducer } from "react";
import FirstTakeSurveyReducer from "../reducers/firstTakeSurveyReducer";

const initState = {};

const FirstTakeSurveyContext = createContext();
const FirstTakeSurveyDispatchContext = createContext();

const FirstTakeSurveyContextProvider = ({ children }) => {
  const [value, dispatch] = useReducer(FirstTakeSurveyReducer, initState);
  return (
    <FirstTakeSurveyContext.Provider value={value}>
      <FirstTakeSurveyDispatchContext.Provider value={dispatch}>
        {children}
      </FirstTakeSurveyDispatchContext.Provider>
    </FirstTakeSurveyContext.Provider>
  );
};

export {
  FirstTakeSurveyContextProvider,
  FirstTakeSurveyContext,
  FirstTakeSurveyDispatchContext,
};
