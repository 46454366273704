import { useState, useEffect, useMemo, useContext } from "react";
import { Form, Input, DatePicker, Spin } from "antd";
import notification from "../../services/notification";
import { MaskedInput } from "antd-mask-input";
import { useTranslation } from "react-i18next";
import { FORMAT } from "../../config/common";
// import style
import "./YourInformation.css";
// import util
import {
  isEmpty,
  notEmpty,
  validateThaiCitizenID,
  trimDash,
  isExpireDate,
} from "../../utilities/util";
import time from "../../utilities/time";
// import api
import { useMutation } from "@apollo/client";
import {
  CANCEL_CHANGE_EMAIL,
  UPDATE_PROFILE,
  RESEND_CHANGE_EMAIL,
} from "../../graphql/mutation";
import { PROFILE, GET_BANK_VERIFICATION_BY_USER } from "../../graphql/query";
import thTh from "antd/es/date-picker/locale/th_TH";
import enUS from "antd/es/date-picker/locale/en_US";
import { CommonDispatchContext } from "../../stateManagement/context/commonContext";
import { ACTIONS } from "../../stateManagement/actions/commonAction";

const preventEdit = false;

const YourInformation = ({ currentUser = {} }) => {
  const dispatch = useContext(CommonDispatchContext);

  const [updateProfile, { loading: loadingUpdateProfile }] = useMutation(
    UPDATE_PROFILE,
    {
      onCompleted: (data) => {
        notification.success({
          message: getText(
            `information.${data?.updateContactAccount?.messageCode}`
          ),
        });
        setDisableSave(true);
        setDisableEmail(true);
        setDisablePhone(true);
        setDisableIdcard(true);
        dispatch({
          type: ACTIONS.SET_CURRENT_USER,
          currentUser: {
            ...currentUser,
            phoneNumber: form.getFieldValue("phoneNumber"),
            identificationNumber: form.getFieldValue("identificationNumber"),
          },
        });
      },
      refetchQueries: [
        {
          query: PROFILE,
        },
        {
          query: GET_BANK_VERIFICATION_BY_USER,
        },
      ],
    }
  );
  const [cancelChangeEmail, { loading: loadingChangeChangeEmail }] =
    useMutation(CANCEL_CHANGE_EMAIL, {
      onCompleted: (data) => {
        notification.success({
          message: getText(
            `account-resend-email.${data?.cancelChangeEmail?.messageCode}`
          ),
        });
      },
      refetchQueries: [{ query: PROFILE }],
    });

  const [resendEmail, { loading: loadingResendEmail }] = useMutation(
    RESEND_CHANGE_EMAIL,
    {
      onCompleted: (data) => {
        notification.success({
          message: getText(
            `information.${data?.resendChangeEmail?.messageCode}`
          ),
        });
      },
      refetchQueries: [{ query: PROFILE }],
    }
  );
  const [disabledEmail, setDisableEmail] = useState(true);
  const [disabledIdcard, setDisableIdcard] = useState(true);
  const [disabledPhone, setDisablePhone] = useState(true);
  const [disabledSave, setDisableSave] = useState(true);
  const [initValue, setInitValue] = useState({});
  const { t: getText, i18n } = useTranslation();
  const getLabel = (text, format, options = {}) =>
    i18n.format(getText(text, options), format);
  const [form] = Form.useForm();
  const loading = useMemo(
    () =>
      loadingChangeChangeEmail || loadingResendEmail || loadingUpdateProfile,
    [loadingChangeChangeEmail, loadingResendEmail, loadingUpdateProfile]
  );

  useEffect(() => {
    if (notEmpty(currentUser)) {
      // console.log(currentUser?.changeEmailPending);
      const value = {
        ...currentUser,
        name: currentUser.fullname,
        dateOfBirth: time.new(new Date(currentUser.dateOfBirth)?.valueOf()),
      };
      form.setFieldsValue(value);
      setInitValue(value);
    }
  }, [currentUser, form]);

  const validateThaiCitizenIDRules = useMemo(() => {
    return [
      () => ({
        validator(_, notParseValue) {
          const value = trimDash(notParseValue);
          if (isEmpty(value)) {
            // return Promise.resolve();
            return Promise.reject(
              new Error(
                getLabel("is-required", "capitalize", {
                  key: getLabel("identification-number", "capitalFirstEach"),
                })
              )
            );
          }
          if (!validateThaiCitizenID(value))
            return Promise.reject(
              new Error(
                getLabel("invalid-error", "capitalize", {
                  key: getLabel("identification-number", "capitalFirstEach"),
                })
              )
            );
          return Promise.resolve();
        },
      }),
    ];
  }, []);

  // const validateMobileNumber = (e) => {
  //   console.log(e);
  // };

  const onSubmitForm = (value) => {
    const inputData = {};
    if (currentUser.email !== value.email) {
      inputData.email = value.email;
    }
    inputData.phoneNumber = value.phoneNumber;
    inputData.identificationNumber = trimDash(value.identificationNumber);
    updateProfile({
      variables: {
        input: inputData,
      },
    });
  };

  return (
    <div id="your-information-container">
      <div id="your-information-label" className="font-bold">
        {i18n.format(getText("your-information"), "capitalize")}
      </div>
      <Spin spinning={loading}>
        <Form
          onFinish={onSubmitForm}
          onValuesChange={(value, allValue) => {
            if (
              trimDash(allValue.identificationNumber) !==
                trimDash(initValue.identificationNumber) ||
              allValue.email !== initValue.email ||
              allValue.phoneNumber !== initValue.phoneNumber
            ) {
              setDisableSave(false);
            } else {
              setDisableSave(true);
            }
          }}
          form={form}
          id="your-information-form"
          layout="vertical"
        >
          <Form.Item
            name="name"
            label={getLabel("your-name", "capitalFirstEach")}
          >
            <Input disabled placeholder={getLabel("your-name", "capitalize")} />
          </Form.Item>
          <Form.Item name="dateOfBirth" label={getLabel("dob")}>
            <DatePicker
              className="w-full"
              disabled
              locale={localStorage.getItem("i18nextLng") === "th" ? thTh : enUS}
              format={FORMAT.DATE_FORMAT}
              placeholder={getLabel("dob", "capitalize")}
              suffixIcon={null}
              allowClear={false}
              showToday={false}
            />
          </Form.Item>
          <Form.Item
            name="identificationNumber"
            label={getLabel("identification-number", "capitalFirstEach")}
            rules={validateThaiCitizenIDRules}
            required
          >
            <MaskedInput
              mask={[{ mask: "0-0000-00000-00-0" }]}
              disabled={disabledIdcard}
              placeholder={getLabel(
                "identification-number",
                "capitalFirstEach"
              )}
              suffix={
                <div
                  className="change-text-button"
                  onClick={() => setDisableIdcard((prev) => !prev)}
                >
                  {getLabel("change", "capitalize")}
                </div>
              }
            />
          </Form.Item>
          <Form.Item
            name="email"
            label={getLabel("email", "capitalize")}
            rules={[
              {
                type: "email",
                message: i18n.format(
                  getText("invalid-error", { key: getText("email") }),
                  "capitalize"
                ),
              },
              {
                required: true,
                message: i18n.format(
                  getText("is-required", { key: getText("email") }),
                  "capitalize"
                ),
              },
            ]}
            extra={
              !isExpireDate(initValue?.changeEmailGeneratedAt) &&
              initValue?.changeEmailPending ? (
                <div>
                  {getLabel("pending-email", "capitalize", {
                    email: initValue?.changeEmailPending,
                  })}
                  <span
                    className="primary-blue pointer font-semibold"
                    onClick={() => resendEmail()}
                  >{` ${i18n.format(
                    getText("resend-email"),
                    "capitalize"
                  )}`}</span>
                </div>
              ) : (
                ""
              )
            }
          >
            <Input
              disabled={disabledEmail}
              placeholder={getLabel("email", "capitalize")}
              suffix={
                <div className="flex gap-2">
                  {!isExpireDate(initValue?.changeEmailGeneratedAt) &&
                    initValue?.changeEmailPending && (
                      <img
                        className="pointer opacity-30"
                        alt=""
                        src="/images/common/close-icon.svg"
                        onClick={() => {
                          cancelChangeEmail();
                        }}
                      />
                    )}
                  <div
                    className="change-text-button"
                    onClick={() => setDisableEmail((prev) => !prev)}
                  >
                    {getLabel("change", "capitalize")}
                  </div>
                </div>
              }
            />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label={getLabel("phone-number", "capitalFirstEach")}
            required
            rules={[
              {
                pattern: /\b\w{9,10}\b/,
                message: i18n.format(
                  getText("invaild-phonenumber"),
                  "capitalize"
                ),
              },
              {
                required: true,
                message: i18n.format(
                  getText("is-required", { key: getText("phone-number") }),
                  "capitalize"
                ),
              },
            ]}
          >
            <MaskedInput
              mask={[{ mask: "0000000000" }]}
              maxLength={10}
              minLength={9}
              disabled={disabledPhone}
              addonBefore="+66"
              placeholder={getLabel("phone-number", "capitalize")}
              suffix={
                <div
                  className="change-text-button"
                  onClick={() => setDisablePhone((prev) => !prev)}
                >
                  {getLabel("change", "capitalize")}
                </div>
              }
            />
          </Form.Item>
          <div id="save-change-button-container" className="w-full">
            <div
              id="save-change-button"
              className={disabledSave ? "disabled" : ""}
              onClick={disabledSave ? () => {} : () => form.submit()}
            >
              {i18n.format(getText("save-change"), "uppercase")}
            </div>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default YourInformation;
