// import handler function
import {
  setNotification,
  clearNotification,
  setRecentProject,
  showModal,
  hideModal,
  setCommonNavTitle,
  setCurrentUser,
  showGlobalLoading,
  hideGlobalLoading,
  setRewardSelected,
  findProject,
  findPartnerCode,
} from "../handlers/commonHandler";
// import handler function
import { ACTIONS } from "../actions/commonAction";

const CommonReducer = (state, { type, ...action }) => {
  switch (type) {
    case ACTIONS.SET_NOTIFICATION:
      return setNotification(state, action);
    case ACTIONS.CLEAR_NOTIFICATION:
      return clearNotification(state);
    case ACTIONS.SET_RECENT_PROJECT:
      return setRecentProject(state, action);
    case ACTIONS.SHOW_CUSTOM_MODAL:
      return showModal(state, action);
    case ACTIONS.HIDE_CUSTOM_MODAL:
      return hideModal(state);
    case ACTIONS.SET_COMMON_NAV_TITLE:
      return setCommonNavTitle(state, action);
    case ACTIONS.SET_CURRENT_USER:
      return setCurrentUser(state, action);
    case ACTIONS.SHOW_GLOBAL_LOADING:
      return showGlobalLoading(state, action);
    case ACTIONS.HIDE_GLOBAL_LOADING:
      return hideGlobalLoading(state, action);
    case ACTIONS.SET_REWARD_SELECTED:
      return setRewardSelected(state, action);
    case ACTIONS.FIND_PROJECT_SCREENING:
      return findProject(state, action);
    case ACTIONS.FIND_PARTNER_CODE:
      return findPartnerCode(state, action);
    default:
      return state;
  }
};

export default CommonReducer;
