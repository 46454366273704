import { ACTIONS } from "../actions/firstTakeSurveyAction";
import {
  answerQuestion,
  removeOtherOption,
  appendAnswer,
  deselectAnswer,
  replaceValue,
  findProject,
} from "../handlers/firstTakeSurveyHandler";

const firstTakeSurveyReducer = (state, { type, ...action }) => {
  switch (type) {
    case ACTIONS.ANSWER_QUESTION:
      return answerQuestion(state, action);
    case ACTIONS.REMOVE_OTHER_ANSWER:
      return removeOtherOption(state, action);
    case ACTIONS.APPEND_ANSWER:
      return appendAnswer(state, action);
    case ACTIONS.DESELECT_ANSWER:
      return deselectAnswer(state, action);
    case ACTIONS.REPLACE_ANSWER:
      return replaceValue(state, action);
    case ACTIONS.PATCH_ANSWER_COLLECTION:
      return action.data;
    default:
      return state;
  }
};

export default firstTakeSurveyReducer;
