const notificationDataToFrontend = (data = {}) => {
  const { notificationUid, notificationPath, ...otherData } = data;
  return {
    uid: notificationUid,
    path: notificationPath,
    ...otherData,
  };
};

const notificationToFrontend = (notiData = {}) => {
  const { totalUnread, data: list, ...otherData } = notiData;
  return {
    ...otherData,
    unread: totalUnread || 0,
    dataList: list?.map(notificationDataToFrontend),
  };
};

export { notificationToFrontend, notificationDataToFrontend };
