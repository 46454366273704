import React from 'react';
import { Popover } from 'antd';
import './PopoverWrapper.css';

const PopoverWrapper = ({ wrapClassName, wrapID, ...otherProps }) => {
  return (
    <div className={wrapClassName} id={wrapID}>
      <Popover {...otherProps} />
    </div>
  )
}

export default PopoverWrapper