const ACTIONS = {
  ANSWER_QUESTION: "answer-question",
  REMOVE_OTHER_ANSWER: "remove-other-answer",
  APPEND_ANSWER: "append-answer",
  DESELECT_ANSWER: "deselect-answer",
  PATCH_ANSWER_COLLECTION: "patch-answer-collection",
  REPLACE_ANSWER: "replace-answer",
};

export { ACTIONS };
