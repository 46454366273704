import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import "./cashDetail.css";
import { CommonContext } from "../../stateManagement/context/commonContext";
import { AccountDispatchConext } from "../../stateManagement/context/accountContext";
import { ACTIONS } from "../../stateManagement/actions/accountAction";
import { Responsive } from "../../utilities/Responsive/responsive";
import { DEVICE } from "../../config/common";
import { useNavigate } from "react-router-dom";
import { displayTextAmount } from "../../utilities/textUtil";
import { useQuery } from "@apollo/client";
import { GET_BANK_VERIFICATION_BY_USER } from "../../graphql/query";

const CashDetail = (props) => {
  const { currentUser } = useContext(CommonContext);
  const amount = displayTextAmount(currentUser?.currentBalance || 0);
  const { loading, data, refetch } = useQuery(GET_BANK_VERIFICATION_BY_USER, {
    fetchPolicy: "network-only",
  });

  const MAP_VERIFY_STATUS = {
    PENDING: "pending-review",
    APPROVED: "verify",
    DISAPPROVED: "link-bank-account",
  };
  const verifyType = (data?.bankVerificationByUser || []).at(-1)?.verifyStatus;
  // console.log("verifyType", verifyType);
  return (
    <div className="cash-detail-wrapper-container">
      <Responsive displayIn={[DEVICE.LAPTOP]}>
        <CashDetailDesktop
          {...props}
          type={MAP_VERIFY_STATUS[verifyType] || "link-bank-account"}
          amount={amount}
        />
      </Responsive>
      <Responsive displayIn={[DEVICE.TABLET, DEVICE.MOBILE]}>
        <CashDetailMobile
          {...props}
          type={MAP_VERIFY_STATUS[verifyType] || "link-bank-account"}
          amount={amount}
        />
      </Responsive>
    </div>
  );
};

const CashDetailMobile = (props) => {
  const { t } = useTranslation();
  const dispatchAccount = useContext(AccountDispatchConext);
  const { format: f } = i18n;
  const navigate = useNavigate();
  const getAccountTypeElement = (type) => {
    const handleClick = () => {
      if(type !== "pending-review") {
        dispatchAccount({
          type: ACTIONS.APPEND_KEY,
          key: "viewScreen",
          value: type,
        });
        navigate(type);
      }
    };
    if (type === "link-bank-account") {
      return (
        <div
          onClick={handleClick}
          className="unselectable cursor-pointer"
          id="link-bank-account-btn"
        >
          {f(t("link-bank-account"), "capitalFirstEach")}
        </div>
      );
    }
    if (type === "pending-review") {
      return (
        <div
          onClick={handleClick}
          className="unselectable"
          id="pending-review-label"
        >
          {f(t("pending-review"), "capitalFirstEach")}
        </div>
      );
    }
    if (type === "verify") {
      return (
        <div
          onClick={handleClick}
          id="verified-text-icon-container"
          className="flex items-center cursor-pointer"
        >
          <img src="/images/account/verified-account.svg" alt="" srcset="" />
          <div className="label">{f(t("verified"), "uppercase")}</div>
        </div>
      );
    }
    return null;
  };
  const accType = props?.type || "link-bank-account";
  return (
    <div id="cash-detail-box-container">
      <div id="cash-detail-label">
        {f(t("cash-details"), "capitalFirstEach")}
      </div>
      <div
        id="payment-account-status-container"
        className={
          accType === "verify" ? "flex justify-between items-center" : ""
        }
      >
        <div id="payment-account-label" className="cash-detail-subtopic-label">
          {f(t("payment-account"), "capitalFirstEach")}
        </div>
        {getAccountTypeElement(accType)}
      </div>
      <div id="balance-history-footer-content-container">
        <div className="balance-section flex justify-between items-center">
          <div id="balance-label" className="cash-detail-subtopic-label">
            {f(t("balance"), "capitalize")}
          </div>
          <div id="balance-amount">{props?.amount ?? 0}</div>
        </div>
        <div className="" id="cash-history-section">
          <div
            id={`cash-out-button`}
            className={`flex justify-center items-center unselectable ${accType !== 'verify' ? `cash-out-button-disabled`:``}`}
            onClick={(e) => {
              e.stopPropagation();
              if(accType === 'verify') {
                dispatchAccount({
                  type: ACTIONS.APPEND_KEY,
                  key: "viewScreen",
                  value: "cash-out",
                });
              navigate("cash-out");
            }else{
                e.preventDefault()
              }
            }}
          >
            <div className="label">{f(t("cash-out"), "uppercase")}</div>
            <img
              src="/images/common/arrow-left.svg"
              style={{ marginLeft: 8, height: 18, width: "auto" }}
              className="filter-white rotate-180"
              alt="back-icon"
            />
          </div>
          <div
            className="unselectable text-center cursor-pointer"
            id="cash-history-text-button"
            onClick={(e) => {
              e.stopPropagation();
              dispatchAccount({
                type: ACTIONS.APPEND_KEY,
                key: "viewScreen",
                value: "cash-out-history",
              });
              navigate("cash-out-history");
            }}
          >
            {f(t("cash-out-history"), "uppercase")}
          </div>
        </div>
      </div>
    </div>
  );
};

const CashDetailDesktop = (props) => {
  const { t } = useTranslation();
  const dispatchAccount = useContext(AccountDispatchConext);
  const { format: f } = i18n;
  const getAccountTypeElement = (type) => {
    const handleClick = () => {
      dispatchAccount({
        type: ACTIONS.APPEND_KEY,
        key: "viewScreen",
        value: type,
      });
    };
    if (type === "link-bank-account") {
      return (
        <div
          onClick={handleClick}
          className="unselectable"
          id="link-bank-account-btn"
        >
          {f(t("link-bank-account"), "capitalFirstEach")}
        </div>
      );
    }
    if (type === "pending-review") {
      return (
        <div
          onClick={handleClick}
          className="unselectable"
          id="pending-review-label"
        >
          {f(t("pending-review"), "capitalFirstEach")}
        </div>
      );
    }
    if (type === "verify") {
      return (
        <div
          onClick={handleClick}
          id="verified-text-icon-container"
          className="flex items-center cursor-pointer"
        >
          <img src="/images/account/verified-account.svg" alt="" srcset="" />
          <div className="label">{f(t("verified"), "uppercase")}</div>
        </div>
      );
    }
    return null;
  };
  const accType = props?.type || "link-bank-account";
  return (
    <div id="cash-detail-box-container">
      <div id="cash-detail-label">{f(t("cash-details"), "uppercase")}</div>
      <div id="payment-account-status-container">
        <div id="payment-account-label" className="cash-detail-subtopic-label">
          {f(t("payment-account"), "capitalFirstEach")}
        </div>
        {getAccountTypeElement(accType)}
      </div>
      <div id="balance-history-footer-content-container">
        <div className="balance-section">
          <div id="balance-label" className="cash-detail-subtopic-label">
            {f(t("balance"), "capitalize")}
          </div>
          <div id="balance-amount">{props?.amount ?? 0}</div>
        </div>
        <div
          className="flex items-center cursor-pointer"
          id="cash-history-section"
        >
          <div
            id="cash-history-text-button"
            onClick={(e) => {
              e.stopPropagation();
              dispatchAccount({
                type: ACTIONS.APPEND_KEY,
                key: "viewScreen",
                value: "cash-out-history",
              });
            }}
          >
            {f(t("cash-out-history"), "uppercase")}
          </div>
          <div
            id="cash-out-button"
            className={`flex items-center ${accType !== 'verify' ? `cash-out-button-disabled`:``} unselectable`}
            onClick={(e) => {
              e.stopPropagation();
              if(accType === 'verify') {
                dispatchAccount({
                  type: ACTIONS.APPEND_KEY,
                  key: "viewScreen",
                  value: "cash-out",
                });
              }else{
                e.preventDefault()
              }
            }}
          >
            <div className="label">{f(t("cash-out"), "uppercase")}</div>
            <img
              src="/images/common/arrow-left.svg"
              style={{ marginLeft: 8, height: 18, width: "auto" }}
              className="filter-white rotate-180"
              alt="back-icon"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashDetail;
