import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Cookies from 'js-cookie';

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}



i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: Cookies.get('lang') || 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
      format: function (value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase();
        if (format === 'lowercase') return value.toLowerCase();
        if (format === 'capitalize') return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
        if (format === 'capitalFirstEach') return value.split(' ').map(capitalize).join(' ');
        return value;
      },
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;