import { createContext, useReducer } from 'react';
import AccountReducer from '../reducers/accountReducer';
const initState = {};

const AccountContext = createContext();
const AccountDispatchConext = createContext();

const AccountContextProvider = ({ children }) => {
  const [value, dispatch] = useReducer(AccountReducer, initState);
  return (
    <AccountContext.Provider value={value}>
      <AccountDispatchConext.Provider value={dispatch}>
        {children}
      </AccountDispatchConext.Provider>
    </AccountContext.Provider>
  );
};

export { AccountContextProvider, AccountContext, AccountDispatchConext };