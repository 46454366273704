import { useState, useContext, useMemo, useEffect } from "react";
import { CommonContext } from "../../stateManagement/context/commonContext";
import { MoreOutlined } from "@ant-design/icons";

import {
  Space,
  Avatar,
  Badge,
  Dropdown,
  Menu,
  Drawer,
  Button,
  Popover,
} from "antd";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import { io } from "socket.io-client";

// import component
import { Responsive } from "../../utilities/Responsive/responsive";
// import constant
import { CONSTANT_VALUE, DEVICE, ROUTE } from "../../config/common";
import { isFunction, notEmpty } from "../../utilities/util";
import { clearToken } from "../../utilities/authenUtil";
import { displayTextAmount } from "../../utilities/textUtil";
import {
  notificationToFrontend,
  notificationDataToFrontend,
} from "../../models/notificationModel";
import _ from "lodash";
// import style
import "./Navbar.css";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_NOTIFICATION, LOGOUT } from "../../graphql/query";
import {
  UPDATE_READ_FLAG,
  UPDATE_READ_FLAG_ALL,
  DELETE_NOTIFICATION_ALL,
} from "../../graphql/mutation";

const logoutCallback = () => {
  clearToken();
  if (process.env.REACT_APP_LANDING_PAGE) {
    window.location.replace(process.env.REACT_APP_LANDING_PAGE);
  } else {
    window.location.replace(ROUTE.UNAUTHEN);
  }
};

const NavButton = ({ callback, title, trailing, ...props }) => {
  return (
    <div
      className="nav-drawer-button pointer"
      onClick={() => {
        if (isFunction(callback)) {
          callback();
        }
      }}
    >
      <div className="nav-drawer-button-leading">{title}</div>
      <div className="nav-drawer-button-trailing">{trailing}</div>
    </div>
  );
};

const Notification = ({ isDesktop = true, mode }) => {
  const [notifications, setNotification] = useState({});
  const { currentUser } = useContext(CommonContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [updateReadFlag] = useMutation(UPDATE_READ_FLAG);
  const [updateReadFlagAll] = useMutation(UPDATE_READ_FLAG_ALL);
  const [deleteNotificationAll] = useMutation(DELETE_NOTIFICATION_ALL);
  const [showNoti, setShowNoti] = useState(false);
  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_NOTI_PATH, {
      reconnectionDelayMax: 1000,
      reconnectionDelay: 1000,
      reconnectionAttempts: Infinity,
    });
    socket.on("connect", function () {
      socket.emit("update_socket_id", currentUser?.uid);
    });
    socket.on("notification", function (data) {
      const newNotiData = notificationDataToFrontend(data?.data);
      setNotification((prev) => ({
        ...prev,
        unread: data.totalUnread,
        dataList: [newNotiData, ...prev.dataList],
      }));
    });
    return () => socket.close();
  }, [currentUser]);
  useQuery(GET_NOTIFICATION, {
    fetchPolicy: "network-only",
    variables: {
      offset: currentPage,
      limit: CONSTANT_VALUE.LIMIT_NOTI,
    },
    onCompleted: (data) => {
      const transformedData = notificationToFrontend(data?.notifications);
      setNotification((prev) => ({
        ...transformedData,
        dataList: _.uniqBy(
          [...(prev?.dataList || []), ...transformedData?.dataList],
          "uid"
        ),
      }));
    },
  });
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const getText = (text, format, options) =>
    i18n.format(t(text, options), format);
  const notiList = useMemo(
    () => notifications?.dataList || [],
    [notifications]
  );

  const notificationItem = (data = {}, index) => {
    const { uid, flagRead, path } = data;
    return (
      <div
        key={index}
        className="notification-item-container"
        onClick={async () => {
          if (!flagRead) {
            await updateReadFlag({ variables: { notificationUid: uid } });
            const newList = notiList?.reduce((acc, noti) => {
              const newNoti = { ...noti };
              if (uid === noti.uid) {
                newNoti.flagRead = true;
              }
              return [...acc, newNoti];
            }, []);
            setNotification((prev) => ({
              ...prev,
              unread: prev.unread >= 0 ? prev.unread - 1 : 0,
              dataList: newList,
            }));
          }
          navigate(path);
          setShowNoti(false);
        }}
      >
        <div>{data?.[`message${i18n.language?.toUpperCase()}`]}</div>
        {!flagRead ? (
          <div className="flag-read" />
        ) : (
          <div className="flag-read-temp" />
        )}
      </div>
    );
  };

  const items = [
    {
      label: getText("marl_all_as_read"),
      key: "marl_all_as_read",
    },
    {
      label: getText("delete_all"),
      key: "delete_all",
    },
  ];

  const onClickNotification = async ({ key }) => {
    switch (key) {
      case "delete_all":
        await deleteNotificationAll();
        setNotification((prev) => ({
          ...prev,
          unread: 0,
          totalRecord: 0,
          dataList: [],
        }));
        break;
      default:
        await updateReadFlagAll();
        const newList = notiList?.reduce((acc, noti) => {
          const newNoti = { ...noti };
          newNoti.flagRead = true;
          return [...acc, newNoti];
        }, []);
        setNotification((prev) => ({
          ...prev,
          unread: 0,
          dataList: newList,
        }));
        break;
    }
  };

  const notificationContent = (
    <div id="notification-content-container">
      <div id="notification-header">
        <div>{getText("notifications", "uppercase")}</div>
        <div className="notification-tool">
          <Dropdown
            menu={{ items, onClick: onClickNotification }}
            trigger={["click"]}
            placement="bottomRight"
            className="border-none text-white hover:text-white bg-transparent cursor-pointer"
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <MoreOutlined color="#FFFFFF" />
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>
      <div id="notification-body">
        {notEmpty(notiList) ? (
          notiList.map((item, index) => notificationItem(item, index))
        ) : (
          <div id="empty-noti-container" className="text-center lightgray ">
            {getText("empty-label", "capitalize", {
              key: getText("notification"),
            })}
          </div>
        )}
      </div>
      {notifications.dataList?.length < notifications.totalRecord && (
        <div
          className="more-noti-button"
          onClick={() => setCurrentPage((prev) => prev + 1)}
        >
          {getText("more", "capitalize")}
        </div>
      )}
    </div>
  );

  if (mode === "drawer") {
    return (
      <NavButton
        title={i18n.format(getText("notifications"), "uppercase")}
        trailing={notifications?.unread}
      />
    );
  }
  return (
    <div id="notification-container" className="pointer">
      <Popover
        open={showNoti}
        overlayClassName="notification-overlay"
        trigger="click"
        content={notificationContent}
        placement="bottomRight"
        onOpenChange={(v) => setShowNoti(v)}
      >
        <Badge offset={[-2, 4]} count={notifications?.unread}>
          {isDesktop ? (
            <Avatar
              className="noti-icon"
              size={42}
              src={<img alt="" src="/images/common/notification-icon.svg" />}
            />
          ) : (
            <img alt="" src="/images/common/notification-icon.svg" />
          )}
        </Badge>
      </Popover>
    </div>
  );
};

const Credit = ({ isDesktop = true }) => {
  const { currentUser } = useContext(CommonContext);
  const amount = displayTextAmount(currentUser?.currentCredit || 0);

  const { t: getText, i18n } = useTranslation();
  if (!isDesktop) {
    return (
      <NavButton
        title={i18n.format(getText("my-balance"), "uppercase")}
        trailing={amount}
      />
    );
  }
  return (
    <div id="wallet-container" className="pointer">
      <Link to={`/account`}>
        <Space>
          <Avatar
            className="wallet-icon"
            size={42}
            src={<img alt="" src="/images/common/credit-money-icon.svg" />}
          />
          <div>
            <div id="amount-title">{amount}</div>
            <div id="amount-text">{getText("i-credit")}</div>
          </div>
        </Space>
      </Link>
    </div>
  );
};

const Wallet = ({ isDesktop = true }) => {
  const { currentUser } = useContext(CommonContext);
  const amount = displayTextAmount(currentUser?.currentBalance || 0);

  const { t: getText, i18n } = useTranslation();
  if (!isDesktop) {
    return (
      <NavButton
        title={i18n.format(getText("my-balance"), "uppercase")}
        trailing={amount}
      />
    );
  }
  return (
    <div id="wallet-container" className="pointer">
      <Link to={`/account`}>
        <Space>
          <Avatar
            className="wallet-icon"
            size={42}
            src={<img alt="" src="/images/common/wallet-money-icon.svg" />}
          />
          <div>
            <div id="amount-title">{amount}</div>
            <div id="amount-text">{getText("baht")}</div>
          </div>
        </Space>
      </Link>
    </div>
  );
};

const CurrentUser = ({ isDesktop = true, logout }) => {
  const { currentUser } = useContext(CommonContext);
  const name = currentUser?.fullname;
  const navigate = useNavigate();
  const { t: getText, i18n } = useTranslation();
  const role = i18n.format(getText("participant"), "capitalize");

  if (!isDesktop) {
    return (
      <NavButton
        title={i18n.format(getText("account"), "uppercase")}
        callback={() => navigate(ROUTE.ACCOUNT.ROOT)}
        trailing={
          <div style={{ textAlign: "right" }}>
            <div className="username-title">{name}</div>
            <div className="user-role-title">
              {i18n.format(getText(`role.${role.toLowerCase()}`), "capitalize")}
            </div>
          </div>
        }
      />
    );
  }
  // const menu = (
  //   <Menu
  //     onClick={({ key }) => {
  //       switch (key) {
  //         case "logout":
  //           logout();
  //           break;
  //         default:
  //       }
  //     }}
  //     items={[
  //       {
  //         key: "account",
  //         label: (
  //           <Link to={ROUTE.ACCOUNT.ROOT} className="dropdown-menu-title">
  //             {i18n.format(getText("account"), "capitalFirstEach")}
  //           </Link>
  //         ),
  //         icon: <img alt="" src="/images/navbar/profile-circle.svg" />,
  //       },
  //       // {
  //       //   key: "refresh-account",
  //       //   label: (
  //       //     <Link to="/" className="dropdown-menu-title">
  //       //       {i18n.format(getText("refresh-account"), "capitalFirstEach")}
  //       //     </Link>
  //       //   ),
  //       //   icon: <img alt="" src="/images/navbar/refresh-right-square.svg" />,
  //       // },
  //       {
  //         key: "logout",
  //         label: (
  //           <div id="logout-menu" className="dropdown-menu-title">
  //             {i18n.format(getText("logout"), "capitalFirstEach")}
  //           </div>
  //         ),
  //         icon: <img alt="" src="/images/navbar/logout.svg" />,
  //       },
  //     ]}
  //   />
  // );
  const items = [
    {
      key: "account",
      label: (
        <Link to={ROUTE.ACCOUNT.ROOT} className="dropdown-menu-title">
          {i18n.format(getText("account"), "capitalFirstEach")}
        </Link>
      ),
      icon: <img alt="" src="/images/navbar/profile-circle.svg" />,
    },
    // {
    //   key: "refresh-account",
    //   label: (
    //     <Link to="/" className="dropdown-menu-title">
    //       {i18n.format(getText("refresh-account"), "capitalFirstEach")}
    //     </Link>
    //   ),
    //   icon: <img alt="" src="/images/navbar/refresh-right-square.svg" />,
    // },
    {
      key: "logout",
      label: (
        <div
          id="logout-menu"
          className="dropdown-menu-title"
          onClick={() => {
            logout();
          }}
        >
          {i18n.format(getText("logout"), "capitalFirstEach")}
        </div>
      ),
      icon: <img alt="" src="/images/navbar/logout.svg" />,
    },
  ];

  return (
    <Dropdown
      overlayClassName="user-profile-dropdown"
      // overlay={menu}
      menu={{ items }}
      trigger="click"
    >
      <div
        id="current-user-container"
        className={`pointer${currentUser?.profilePic ? " has-avatar" : ""}`}
      >
        <Space>
          <Avatar
            className="user-icon"
            size={42}
            src={
              <img
                alt=""
                src={currentUser?.profilePic || "/images/common/user-icon.svg"}
              />
            }
          />
          <div>
            <Space>
              <div className="username-title">{name}</div>
              <img alt="" src="/images/common/arrow-down.svg" />
            </Space>
            <div className="user-role-title">
              {i18n.format(getText(`role.${role.toLowerCase()}`), "capitalize")}
            </div>
          </div>
        </Space>
      </div>
    </Dropdown>
  );
};

const NavbarDesktop = ({ logout }) => {
  const { t: getText, i18n } = useTranslation();
  return (
    <div id="navbar-container">
      <div id="navbar-leading">
        <img alt="" src="/images/common/inline-icon.svg" />
        <Space size="large" className="menu-container">
          <NavLink className="nav-menu" to={ROUTE.ROOT} end>
            {i18n.format(getText("studies"), "uppercase")}
          </NavLink>
          <NavLink className="nav-menu" to={ROUTE.SUBMISSIONS}>
            {i18n.format(getText("submissions"), "uppercase")}
          </NavLink>
          <NavLink className="nav-menu" to={ROUTE.REWARDS}>
            {i18n.format(getText("rewards"), "uppercase")}
          </NavLink>
          <NavLink className="nav-menu" to={ROUTE.SCREENING_QUESTION}>
            {i18n.format(getText("screening-question"), "uppercase")}
          </NavLink>
        </Space>
      </div>
      <div id="navbar-trailing">
        <Space size="large">
          <Notification />
          <Credit />
          <Wallet />
          <CurrentUser logout={logout} />
        </Space>
      </div>
    </div>
  );
};

const NavbarMobile = ({ logout }) => {
  const { t: getText, i18n } = useTranslation();
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  return (
    <>
      <Drawer
        className="nav-drawer"
        closable={false}
        placement="top"
        open={visibleDrawer}
        onClose={() => {
          setVisibleDrawer(false);
        }}
      >
        <Space direction="vertical" className="full-width">
          <div id="nav-header">
            <div></div>
            <div id="nav-drawer-header-title">
              {i18n.format(getText("menu"), "uppercase")}
            </div>
            <img
              id="close-nav-icon"
              className="pointer"
              src="/images/common/cross-icon.svg"
              alt=""
              onClick={() => setVisibleDrawer(false)}
            />
          </div>
          <Notification isDesktop={false} mode="drawer" />
          <Wallet isDesktop={false} />
          <CurrentUser isDesktop={false} />
          <Button className="full-width" type="primary" onClick={logout}>
            {i18n.format(getText("logout"), "capitalFirstEach")}
          </Button>
        </Space>
      </Drawer>
      <div id="navbar-container">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: 10,
          }}
          className="full-width"
        >
          <img
            className="pointer"
            src="/images/navbar/hamberger-icon.svg"
            alt=""
            onClick={() => {
              setVisibleDrawer(true);
            }}
          />
          <img src="/images/common/mini-logo.svg" alt="" />
          <Notification isDesktop={false} />
        </div>
        <div id="nav-menu-mobile-container">
          <div id="nav-menu-mobile-container-scroller">
            <div className="menu-container">
              <NavLink className="nav-menu" to={ROUTE.ROOT} end>
                {i18n.format(getText("studies"), "uppercase")}
              </NavLink>
              <NavLink className="nav-menu" to={ROUTE.SUBMISSIONS}>
                {i18n.format(getText("submissions"), "uppercase")}
              </NavLink>
              <NavLink className="nav-menu" to={ROUTE.REWARDS}>
                {i18n.format(getText("rewards"), "uppercase")}
              </NavLink>
              <NavLink className="nav-menu" to={ROUTE.SCREENING_QUESTION}>
                {i18n.format(getText("screening-question"), "uppercase")}
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Navbar = () => {
  const { pathname } = useLocation();
  const currentPath = pathname.split("/")?.[1];
  const [logout] = useLazyQuery(LOGOUT, {
    fetchPolicy: "network-only",
    onError: () => {
      logoutCallback();
    },
    onCompleted: () => {
      logoutCallback();
    },
  });

  return (
    <>
      <Responsive displayIn={[DEVICE.LAPTOP]}>
        <NavbarDesktop logout={logout} />
      </Responsive>
      <Responsive displayIn={[DEVICE.TABLET, DEVICE.MOBILE]}>
        {currentPath !== ROUTE.ACCOUNT.ROOT && <NavbarMobile logout={logout} />}
      </Responsive>
    </>
  );
};

export default Navbar;

const CommonNavbar = () => {
  const navigate = useNavigate();
  const commonContext = useContext(CommonContext);
  const { t: getText, i18n } = useTranslation();
  return (
    <div className="common-navbar-container">
      <img
        id="back-button"
        src="/images/common/chevron-left.svg"
        alt=""
        height={15}
        onClick={() => {
          if (commonContext?.commonNavTitle?.backRoute) {
            navigate(commonContext?.commonNavTitle?.backRoute, {
              replace: true,
            });
          } else {
            navigate(-1);
          }
        }}
      />
      <div className="common-navbar-title">
        {i18n.format(
          getText(
            commonContext?.commonNavTitle?.text || commonContext?.commonNavTitle
          ),
          commonContext?.commonNavTitle?.format || "capitalize"
        )}
      </div>
    </div>
  );
};

export { CommonNavbar };
