import Cookies from "js-cookie";
import jwt from "jwt-decode";
import psl from "psl";

const checkLogin = () => {
  const userToken = Cookies.get("token");
  let decodedToken;
  if (userToken) {
    try {
      decodedToken = jwt(userToken);
      return { isLogin: true, role: decodedToken?.role };
    } catch (err) {
      return { isLogin: false, errorMessage: "invalid-token" };
    }
  }
  return { isLogin: false, errorMessage: "login-first" };
};

const isLocalhost =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1";

const clearToken = () => {
  const domain = psl.get(window.location.hostname);
  Cookies.remove("request-uid", {
    domain: isLocalhost ? "" : `.${domain}`,
  });
  Cookies.remove("token", {
    domain: isLocalhost ? "" : `.${domain}`,
  });
  Cookies.remove("refreshToken", {
    domain: isLocalhost ? "" : `.${domain}`,
  });
};

const setToken = ({ token, refreshToken }) => {
  const domain = psl.get(window.location.hostname);
  Cookies.set("token", token, {
    domain: !isLocalhost ? `.${domain}` : "",
  });
  Cookies.set("refreshToken", refreshToken, {
    domain: !isLocalhost ? `.${domain}` : "",
  });
};

const getToken = () => {
  if (typeof window === `undefined`) return undefined;
  return Cookies.get("token");
};

const getRefreshToken = () => {
  if (typeof window === `undefined`) return undefined;
  return Cookies.get("refreshToken");
};

const isTokenExpired = (token) => jwt(token)?.exp <= Date.now() / 1000;

export {
  setToken,
  getToken,
  getRefreshToken,
  isTokenExpired,
  checkLogin,
  clearToken,
  isLocalhost,
};
