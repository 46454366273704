import React from "react";
import ReactDOM from "react-dom/client";
import "../src/styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ContextBundler from "./ContextBundler";
import { ApolloProvider } from "@apollo/client";
import client from "./utilities/apolloClient";
import * as Sentry from "@sentry/react";
import ReactGA from "react-ga";
import { BrowserTracing } from "@sentry/tracing";

import "./i18n";
// REACT_APP_SENTRY_DSN

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactGA.initialize(process.env.REACT_APP_GOOGLE_TAG_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  //<React.StrictMode>
  <ApolloProvider client={client}>
    <ContextBundler>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ContextBundler>
  </ApolloProvider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
