import React from 'react';
import { CommonProvider } from './stateManagement/context/commonContext';
import { FirstTakeSurveyContextProvider } from './stateManagement/context/firstTakeSurveyContext';

const ContextBundler = ({ children }) => {
  return (
    <CommonProvider>
      <FirstTakeSurveyContextProvider>
        {children}
      </FirstTakeSurveyContextProvider>
    </CommonProvider>
  );
};

export default ContextBundler;