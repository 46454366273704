import "./CashOutHistory.css";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Spin } from "antd";
import { ACTIONS } from "../../stateManagement/actions/accountAction";
import { AccountDispatchConext } from "../../stateManagement/context/accountContext";
// import cashoutList from "../../mock/cashOutHistoryMock";
import time from "../../utilities/time";
import { FORMAT, RATE, DEVICE } from "../../config/common";
import { Responsive } from "../../utilities/Responsive/responsive";
import { useQuery } from "@apollo/client";
import { WITHDRAW_TRANSACTION_LIST } from "../../graphql/query";
import toCashOutData from "./CashoutHistoryModel";
import { displayTextAmount } from "../../utilities/textUtil";

const TransactionElement = (props) => {
  const { t } = useTranslation();
  const { format: f } = i18n;
  const { timeStamp, amount } = props;
  const STATUS_MAP = {
    PENDING: "pending",
    APPROVED: "success",
    DISAPPROVE: "disapprove",
  };

  const getColorByStatus = (status) => {
    if (status === "PENDING") return "#939393";
    if (status === "APPROVED") return "#22a85b";
    if (status === "DISAPPROVE") return "#F46436";
  };
  return (
    <div className="transaction-element-container">
      <div className="transaction-time-label">
        {timeStamp
          ? time.displayAs(timeStamp, FORMAT.DATE_FORMAT_WITH_TIME)
          : ""}
      </div>
      <div className="flex items-center justify-between" id="withdrawal-row">
        <div className="label">{f(t("withdrawal"), "capitalize")}</div>
        <div
          style={{
            color: getColorByStatus(props?.status),
          }}
          className="value"
        >
          {displayTextAmount(amount)}
        </div>
      </div>
      <div
        className="flex items-center justify-between"
        id="transaction-fee-row"
      >
        <div className="label">
          {f(t("transaction-fee"), "capitalFirstEach")}
        </div>
        <div className="value">{props?.transactionFee}</div>
      </div>
      <div className="flex items-center justify-between" id="wht-row">
        <div className="label">
          {`${f(t("wht"), "uppercase")} (${Math.abs(RATE.WHT * 100)}%)`}
        </div>
        <div className="value">{props?.wht}</div>
      </div>
      <div
        className={`transaction-status-badge ${props?.status?.toLowerCase()}`.trim()}
      >
        {f(t(STATUS_MAP[props?.status]), "capitalFirstEach")}
      </div>
      {props?.status && props?.status === "APPROVED" && (
        <span id="cashout-info-24hr">
          ({f(t("cashout-info-24hr"), "capitalFirstEach")})
        </span>
      )}
      {/* (เงินจะถูกโอนเข้าบัญชีที่ลงทะเบียนไว้กับเราภายใน 24 ชั่วโมง) */}
      {/* (Money will be transferred to the registered bank account within 24 hours) */}
    </div>
  );
};

const CashOutHistory = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const dispatchAction = useContext(AccountDispatchConext);
  const { loading, data } = useQuery(WITHDRAW_TRANSACTION_LIST, {
    fetchPolicy: "network-only",
    variables: {
      offset: currentPage,
      limit: 5,
    },
  });
  const { format: f } = i18n;
  const closeForm = (e) => {
    e.stopPropagation();
    dispatchAction({
      type: ACTIONS.APPEND_KEY,
      key: "viewScreen",
      value: null,
    });
  };
  const cashoutList = loading ? [] : toCashOutData(data);
  const transactionListElement = cashoutList.map((t) => (
    <TransactionElement {...t} />
  ));
  const totalPage = data?.withdrawTransaction?.totalRecord
    ? Math.ceil(data?.withdrawTransaction?.totalRecord / 5)
    : 0;
  const disablePrev = currentPage === 1;
  const disableNext =
    currentPage === totalPage || data?.withdrawTransaction?.totalRecord <= 0;
  return (
    <div id="cash-out-history-container">
      <Spin spinning={loading}>
        <div
          className="flex items-center justify-between"
          id="cash-out-history-header"
        >
          <div id="cash-out-history-label">
            {f(t("cash-out-history"), "uppercase")}
          </div>
          <Responsive displayIn={[DEVICE.LAPTOP]}>
            <div
              onClick={closeForm}
              className="unselectable flex cursor-pointer items-center"
              id="close-section-text-button"
            >
              {`X ${f(t("close"), "capitalize")}`}
            </div>
          </Responsive>
        </div>
        <div id="cash-out-transaction-wrapper">{transactionListElement}</div>
        <Responsive displayIn={[DEVICE.LAPTOP]}>
          <div
            className="flex items-center justify-between"
            id="cash-out-history-footer"
          >
            <div
              onClick={
                disablePrev
                  ? () => {}
                  : (e) => {
                      e.stopPropagation();
                      setCurrentPage((page) => page - 1);
                    }
              }
              id="previous-button"
              className={`flex items-center unselectable control-button${
                disablePrev ? "-disabled" : ""
              }`.trim()}
            >
              <div className="icon-container flex items-center">
                <img
                  src="/images/common/arrow-left.svg"
                  alt="previous-icon"
                  style={{ marginRight: 8 }}
                />
              </div>
              <div className="label">{f(t("previous"), "uppercase")}</div>
            </div>
            <div
              id="next-button"
              onClick={
                disableNext
                  ? () => {}
                  : (e) => {
                      e.stopPropagation();
                      setCurrentPage((page) => page + 1);
                    }
              }
              className={`flex items-center unselectable control-button${
                disableNext ? "-disabled" : ""
              }`.trim()}
            >
              <div className="label">{f(t("next"), "uppercase")}</div>
              <div className="icon-container flex items-center rotate-180">
                <img
                  src="/images/common/arrow-left.svg"
                  alt="next-icon"
                  style={{ marginRight: 8 }}
                />
              </div>
            </div>
          </div>
        </Responsive>
      </Spin>
    </div>
  );
};

export default CashOutHistory;
