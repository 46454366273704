import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import "./cashout.css";
import TextInput from "../common/TextInputWrapper";
import CashOutTips from "./CashOutTips";
import { AccountDispatchConext } from "../../stateManagement/context/accountContext";
import { ACTIONS } from "../../stateManagement/actions/accountAction";
import Popover from "../common/PopoverWrapper";
import { isEmpty } from "../../utilities/util";
import { RATE, DEVICE } from "../../config/common";
import { Responsive } from "../../utilities/Responsive/responsive";
import { CASH_OUT_WITHDRAW } from "../../graphql/mutation";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Spin } from "antd";
import notification from "../../services/notification";
import {
  CommonContext,
  CommonDispatchContext,
} from "../../stateManagement/context/commonContext";
import { displayTextAmount } from "../../utilities/textUtil";

const CashOut = ({ onFinish }) => {
  const { currentUser } = useContext(CommonContext);
  const currentBalance = displayTextAmount(currentUser?.currentBalance || 0);
  const dispatch = useContext(CommonDispatchContext);
  const [price, setPrice] = useState("");
  const { t } = useTranslation();
  const dispatchAccount = useContext(AccountDispatchConext);
  const { format: f } = i18n;

  const [cashout, { loading }] = useMutation(CASH_OUT_WITHDRAW, {
    onCompleted: (data) => {
      notification.success({
        message: f(`cashout.${data?.cashOutWithdraw?.messageCode}`),
      });
      onFinish(true);
      closeForm();
    },
  });

  const closeForm = (e) => {
    e?.stopPropagation();
    dispatchAccount({
      type: ACTIONS.APPEND_KEY,
      key: "viewScreen",
      value: null,
    });
  };

  const whtDescriptionContent = (
    <div className="wht-description-container">{t("wht-description")}</div>
  );

  const baseWithdrawalPrice =
    price[price.length - 1] === "."
      ? Number(price.slice(0, -1))
      : Number(price);

  const handleKeyPress = (event) => {
    const key = event.key;
    const dotIndex = price?.indexOf(".");
    if (key === "." && dotIndex !== -1) {
      event.preventDefault();
    } else if (/^[0-9.]$/.test(key)) {
      // Handle digits and dot key
      if (key === "." && dotIndex !== -1) {
        // Check if there are already two digits after the dot
        if (dotIndex) {
          const digitsAfterDot = price?.substring(dotIndex + 1);
          if (digitsAfterDot && digitsAfterDot.length >= 2) {
            event.preventDefault();
            return;
          }
        }
      } else {
        if (dotIndex !== -1 && dotIndex) {
          const digitsAfterDot = price?.substring(dotIndex + 1);
          if (digitsAfterDot && digitsAfterDot.length >= 2) {
            event.preventDefault();
            return;
          }
        }
      }
    }
  };

  const handleTextInputChange = (e) => {
    const re2 = /^\d*(\.\d{0,2})?$/;
    const text = e.target.value.trim();
    // if (isEmpty(text) || re2.test(text)) {
    //   if (text > parseFloat(currentBalance)) {
    //     setPrice(parseFloat(currentBalance));
    //   } else {
    setPrice(text);
    //   }
    // }
  };

  const transactionFeePrice = RATE.TRANSACTION_FEE;

  const whtPrice =
    baseWithdrawalPrice >= 1000
      ? baseWithdrawalPrice * RATE.WHT
      : 0;
  const totalPrice =
    price > 0 ? baseWithdrawalPrice - transactionFeePrice - whtPrice : 0;

  return (
    <Spin spinning={loading}>
      <div id="cash-out-container">
        <Responsive displayIn={[DEVICE.LAPTOP]}>
          <div
            className="flex items-center justify-between"
            id="cash-out-header"
          >
            <div id="cash-out-label">{f(t("cash-out"), "uppercase")}</div>
            <div
              onClick={closeForm}
              className="unselectable flex cursor-pointer"
              id="close-section-text-button"
            >
              {`X ${f(t("close"), "capitalize")}`}
            </div>
          </div>
        </Responsive>
        <div id="transfer-details-container">
          <div id="transfer-detail-label">
            {f(t("transfer-details"), "uppercase")}
          </div>
          <TextInput
            wrapClassName="transfer-amount-text-input"
            placeholder="0.00"
            onChange={handleTextInputChange}
            value={price}
          />
        </div>
        <CashOutTips />
        <div id="transfer-summary-label">
          {f(t("transfer-summary"), "capitalFirstEach")}
        </div>
        <div
          className="flex justify-between items-center money-row"
          id="withdrawal-row"
        >
          <div className="label">{f(t("withdrawal"), "capitalize")}</div>
          <div className="amount">{`${baseWithdrawalPrice.toFixed(2)} ${f(
            t("currency.thb"),
            "capitalize"
          )}`}</div>
        </div>
        <div
          className="flex justify-between items-center money-row"
          id="transaction-fee-row"
        >
          <div className="label">
            {f(t("transaction-fee"), "capitalFirstEach")}
          </div>
          <div className="amount">
            {`${
              price > 0 && transactionFeePrice.toFixed(2) > 0
                ? `-${transactionFeePrice.toFixed(2)}`
                : 0
            } ${f(t("currency.thb"), "capitalize")}`}
          </div>
        </div>
        <div
          className="flex justify-between items-center money-row"
          id="wht-row"
        >
          <div className="flex items-center">
            <div className="label">{f(t("wht"), "uppercase")}</div>
            <Popover
              wrapClassName="wht-description-popover-container"
              trigger="hover"
              placement="bottom"
              content={whtDescriptionContent}
              overlayClassName="wht-popover-content-container"
            >
              <div className="wht-popover-trigger-container">
                <img src="/icon/info-circle.svg" alt="" srcset="" />
              </div>
            </Popover>
          </div>
          <div className="amount">
            {`${whtPrice.toFixed(2) > 0 ? `-${whtPrice.toFixed(2)}` : 0} ${f(
              t("currency.thb"),
              "capitalize"
            )}`}
          </div>
        </div>
        <div
          className="flex justify-between items center money-row-bold"
          id="total-row"
        >
          <div className="label">{f(t("total"), "capitalize")}</div>
          <div className="amount">{`${totalPrice.toFixed(2)} ${f(
            t("currency.thb"),
            "capitalize"
          )}`}</div>
        </div>
        <div id="term-and-condition-container">
          {t("by-clicking-submit-label")}
          <span className="unselectable text-label-button">
            {f(t("term-and-condition"), "capitalFirstEach")}
          </span>
        </div>
        <div
          className="unselectable text-center cursor-pointer"
          id={`confirm-withdrawal-button${
            isEmpty(totalPrice) || parseFloat(totalPrice) <= parseFloat("0.00")
              ? "-disabled"
              : ""
          }`.trim()}
          onClick={(e) => {
            e.stopPropagation();
            if (
              !isEmpty(totalPrice) ||
              !parseFloat(totalPrice) <= parseFloat("0.00")
            ) {
              cashout({
                variables: { input: { withdrawal: parseFloat(price) } },
              });
            }
          }}
        >
          {f(t("confirm-withdrawal"), "capitalFirstEach")}
        </div>
      </div>
    </Spin>
  );
};

export default CashOut;
