/* eslint-disable no-useless-escape */
const isEmpty = (obj) =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;
const notEmpty = (obj) => !isEmpty(obj);
const isFunction = (checker) => typeof checker === "function";
const isNumber = (tester) => /^\d+$/.test(tester);
const notNumber = (tester) => !isNumber(tester);
const isEmail = (tester) =>
  tester.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
const getFlag = (country) => `https://countryflagsapi.com/png/${country}`;
const isString = (str) => str != null && typeof str.valueOf() === "string";
const containDuplicate = (list) =>
  list.some((el) => list.indexOf(el) !== list.lastIndexOf(el));
const uniqueList = (list) => !containDuplicate(list);
const validateThaiCitizenID = (id) => {
  // eslint-disable-next-line eqeqeq
  if (id.length != 13 || id.charAt(0).match(/[09]/)) return false;

  var sum = 0;
  for (let i = 0; i < 12; i++) {
    sum += parseInt(id.charAt(i)) * (13 - i);
  }

  // eslint-disable-next-line eqeqeq
  if ((11 - (sum % 11)) % 10 != parseInt(id.charAt(12))) {
    return false;
  }

  return true;
};
const isNumeric = (num) => /^-{0,1}\d*\.{0,1}\d+$/.test(num);

const trimDash = (value) => value?.split("-")?.join("");

const isExpireDate = (date) => {
  const oneDay = 60 * 60 * 24 * 1000;
  const currentTime = +new Date();
  const createdAt = +new Date(date);
  const compareDatesBoolean = currentTime - createdAt > oneDay;
  return compareDatesBoolean;
};

export {
  getFlag,
  isEmpty,
  notEmpty,
  isFunction,
  isNumber,
  notNumber,
  isEmail,
  isString,
  containDuplicate,
  uniqueList,
  validateThaiCitizenID,
  trimDash,
  isNumeric,
  isExpireDate,
};
