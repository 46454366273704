import React, { useState, useContext, useEffect, useMemo } from "react";
import "./linkBankAccount.css";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Empty, Select, Spin, Alert, Space, Form } from "antd";
import notification from "../../services/notification";
import Cookies from "js-cookie";
import { CaretDownFilled } from "@ant-design/icons";
import TextInput from "../common/TextInputWrapper";
import SelectInput from "../common/SelectInputWrapper";
import FileUploader from "../common/DragAndDropWrapper";
import {
  isNumber,
  notEmpty,
  isEmpty,
  isString,
  trimDash,
  validateThaiCitizenID,
} from "../../utilities/util";
import InstructionDetail from "./InstructionDetail";
import { AccountDispatchConext } from "../../stateManagement/context/accountContext";
import { ACTIONS } from "../../stateManagement/actions/accountAction";
import { ACTIONS as ACTIONS_COMMON } from "../../stateManagement/actions/commonAction";
import { Responsive } from "../../utilities/Responsive/responsive";
import { DEVICE } from "../../config/common";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { MaskedInput } from "antd-mask-input";

import {
  LIST_BANK_PROVIDERS,
  GET_BANK_VERIFICATION_BY_USER,
  PROFILE,
} from "../../graphql/query";
import { SEND_VERIFICATION, UPDATE_PROFILE } from "../../graphql/mutation";
import {
  CommonContext,
  CommonDispatchContext,
} from "../../stateManagement/context/commonContext";

const { Option } = Select;

const toBankListObject = (list) => {
  return (list || []).reduce((acc, b) => {
    const {
      bankShortName,
      uid: code,
      bankOfficialName: official_name_en,
      bankColor: color,
      bankNiceNameEN: nice_name_en,
      bankNiceNameTH: nice_name_th,
      swiftCode,
    } = b;
    return {
      ...acc,
      [bankShortName]: {
        code,
        color,
        official_name_en,
        nice_name_en,
        nice_name_th,
        swiftCode,
      },
    };
  }, {});
};

const LinkBankAccount = ({ lang }) => {
  const { t } = useTranslation();
  const dispatchAccount = useContext(AccountDispatchConext);
  const dispatch = useContext(CommonDispatchContext);
  const { currentUser } = useContext(CommonContext);
  const { t: getText, i18n } = useTranslation();
  const { format: f } = i18n;
  const getLabel = (text, format, options = {}) =>
    i18n.format(getText(text, options), format);
  const currentLang = Cookies.get("lang");
  const fileType = ["JPG", "JPEG", "PNG"];
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [identificationNumber, setIdentificationNumber] = useState(null);
  const [bookBank, setBookBank] = useState(null);
  const [IDCard, setCard] = useState(null);
  const [accountName, setAccountName] = useState(null);
  const [accountNumber, setAccountNumber] = useState(null);
  const [bank, setBank] = useState(null);
  const [bankCode, setBankCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const arrayBankCodeTwoDigi = ["GSBATHBK", "BAABTHBK","GOHUTHB1"];
  const [form] = Form.useForm();
  const [disabledIdcard, setDisableIdcard] = useState(true);
  const [disabledPhone, setDisablePhone] = useState(true);
  const [disabledSave, setDisableSave] = useState(true);
  const [initValue, setInitValue] = useState({});

  // Fetch Bank API Data.
  const [
    getListBankProviders,
    { data: bankListRespData, loading: bankLoading },
  ] = useLazyQuery(LIST_BANK_PROVIDERS, {
    fetchPolicy: "network-only",
  });

  // Fetch Bank Verification detail by user when form has been intialized.
  const { data: BankVerificationRespData, loading: bankDataLoading } = useQuery(
    GET_BANK_VERIFICATION_BY_USER,
    {
      fetchPolicy: "network-only",
    }
  );

  // Use Wen API BankVerificationRespData has been arrived.
  useEffect(() => {
    // console.log(BankVerificationRespData);
    const { bankVerificationByUser } = BankVerificationRespData || {};
    const latestData = (bankVerificationByUser || []).at(-1);
    if (notEmpty(latestData)) {
      setAccountName(latestData?.holderName);
      setAccountNumber(latestData?.bankNo);
      setBank(latestData?.provider?.at(-1)?.uid);
      setBankCode(latestData?.provider?.at(-1).swiftCode);
      setBookBank(latestData?.bookBankImageName);
      setCard(latestData?.idCardImageName);
    }
  }, [BankVerificationRespData]);

  useEffect(() => {
    if (notEmpty(currentUser)) {
      setPhoneNumber(currentUser?.phoneNumber);
      setIdentificationNumber(currentUser?.identificationNumber);
      setInitValue({
        phoneNumber: currentUser?.phoneNumber,
        identificationNumber: currentUser?.identificationNumber,
      });
    }
  }, [currentUser]);

  useEffect(() => {
    let textLang = "EN"
    if(!lang && currentUser) {
      textLang = currentUser?.language === "THAI" ? "TH" : "EN"
    }else{
      textLang = lang
    }
  getListBankProviders({
      variables: { lang: textLang },
    });
  }, [lang,currentUser]);


  const bankList = toBankListObject(bankListRespData?.bankProviders);

  const [sendVerification] = useMutation(SEND_VERIFICATION, {
    onCompleted: (data) => {
      notification.success({
        message: f(`verification.${data?.createBankVerification?.messageCode}`),
      });
    },
    refetchQueries: [
      {
        query: PROFILE,
      },
      {
        query: GET_BANK_VERIFICATION_BY_USER,
      },
    ],
  });

  const handleBookBankImage = (file) => {
    var sizeInMB = parseFloat(file?.size / (1024 * 1024).toFixed(2));
    if (sizeInMB > 8) {
      notification.error({
        message: f(`image-max-size`),
      });
      setBookBank(null);
    } else {
      setBookBank(file);
    }
  };
  const handleIDCardImage = (file) => {
    var sizeInMB = parseFloat(file?.size / (1024 * 1024).toFixed(2));
    if (sizeInMB > 8) {
      notification.error({
        message: f(`image-max-size`),
      });
      setCard(null);
    } else {
      setCard(file);
    }
  };
  const closeForm = (e) => {
    e.stopPropagation();
    dispatchAccount({
      type: ACTIONS.APPEND_KEY,
      key: "viewScreen",
      value: null,
    });
  };
  const handleText = (e) => {
    const text = e.target.value;
    setAccountName(text);
  };
  const onAccountNumberChange = (e) => {
    const text = e.target.value;
    var maxLength =
      bankCode && arrayBankCodeTwoDigi.includes(bankCode) ? 12 : 10; //GSBATHBK fix 12 digi
    if ((isNumber(text) && text.length <= maxLength) || text === "") {
      setAccountNumber(text.trim());
    }
  };
  const disabledSubmitButton =
    isEmpty(accountName) ||
    isEmpty(accountNumber) ||
    isEmpty(IDCard) ||
    isEmpty(bookBank) ||
    isEmpty(phoneNumber) ||
    isEmpty(identificationNumber) ||
    isEmpty(bank);

  const handleSubmitBookBankDetail = async (payload) => {
    try {
      setLoading(true);
      await sendVerification({
        variables: {
          input: {
            bankNo: payload?.bankNo?.trim(),
            bankProviderId: payload?.bankProviderId?.trim(),
            holderName: payload?.holderName?.trim(),
          },
          bookBankImage: isString(payload?.bookBankImage)
            ? undefined
            : payload?.bookBankImage,
          idCardImage: isString(payload?.idCardImage)
            ? undefined
            : payload?.idCardImage,
        },
      });
      dispatchAccount({
        type: ACTIONS.APPEND_KEY,
        key: "viewScreen",
        value: null,
      });
    } catch (err) {
      console.error(err?.response?.data || err?.respose || err || "failed");
    } finally {
      setLoading(false);
    }
  };

  const validateThaiCitizenIDRules = useMemo(() => {
    return [
      () => ({
        validator(_, notParseValue) {
          const value = trimDash(notParseValue);
          if (isEmpty(value)) {
            // return Promise.resolve();
            return Promise.reject(
              new Error(
                getLabel("is-required", "capitalize", {
                  key: getLabel("identification-number", "capitalFirstEach"),
                })
              )
            );
          }
          if (!validateThaiCitizenID(value))
            return Promise.reject(
              new Error(
                getLabel("invalid-error", "capitalize", {
                  key: getLabel("identification-number", "capitalFirstEach"),
                })
              )
            );
          return Promise.resolve();
        },
      }),
    ];
  }, []);
  const [updateProfile, { loading: loadingUpdateProfile }] = useMutation(
    UPDATE_PROFILE,
    {
      onCompleted: (data) => {
        notification.success({
          message: getText(
            `information.${data?.updateContactAccount?.messageCode}`
          ),
        });
        setDisablePhone(true);
        setDisableIdcard(true);
        dispatch({
          type: ACTIONS_COMMON.SET_CURRENT_USER,
          currentUser: {
            ...currentUser,
            phoneNumber: phoneNumber
              ? phoneNumber
              : form.getFieldValue("phoneNumber"),
            identificationNumber: identificationNumber
              ? identificationNumber
              : form.getFieldValue("identificationNumber"),
          },
        });
      },
      refetchQueries: [
        {
          query: PROFILE,
        },
      ],
    }
  );
  const onSubmitForm = (value) => {
    updateProfile({
      variables: {
        input: {
          ...(value.phoneNumber ? { phoneNumber: value.phoneNumber } : null),
          ...(value.identificationNumber
            ? { identificationNumber: trimDash(value.identificationNumber) }
            : null),
        },
      },
    });
  };

  return (
    <Spin
      spinning={
        loading || bankLoading || bankDataLoading || loadingUpdateProfile
      }
    >
      <div id="link-bank-account-container">
        <Responsive displayIn={[DEVICE.LAPTOP]}>
          <div
            className="flex items-center justify-between"
            id="verify-account-header"
          >
            <div id="verify-account-label">
              {f(t("verify-account"), "uppercase")}
            </div>
            <div
              onClick={closeForm}
              className="flex cursor-pointer items-center"
              id="close-section-text-button"
            >
              {`X ${f(t("close"), "capitalize")}`}
            </div>
          </div>
        </Responsive>
        {(!phoneNumber || !identificationNumber) && (
          <Space
            style={{ marginTop: 10, marginBottom: 10, display: "block" }}
            size="middle"
          >
            <Alert
              message={
                <span className="text-[14px]">
                  Please correct "YOUR INFORMATION" the following in the form
                  fields below:
                </span>
              }
              description={
                <>
                  <ul
                    style={{
                      padding: 0,
                      margin: 0,
                      listStyle: "none",
                      width: "100%",
                    }}
                  >
                    {!identificationNumber && (
                      <li key={`identificationNumber`}>
                        -{" "}
                        {i18n.format(
                          getText("is-required", {
                            key: "identification number",
                            // key: getText("identification-number"),
                          }),
                          "capitalize"
                        )}{" "}
                      </li>
                    )}
                    {!phoneNumber && (
                      <li key={`phoneNumber`}>
                        -{" "}
                        {i18n.format(
                          getText("is-required", {
                            key: "phone number",
                            // key: getText("phone-number"),
                          }),
                          "capitalize"
                        )}
                      </li>
                    )}
                  </ul>
                </>
              }
              type="warning"
            />
          </Space>
        )}

        {(!phoneNumber || !identificationNumber) && (
          <>
            <div id="bank-detail-label">
              {f(t("bank-details"), "uppercase")}
            </div>
            <Form
              onFinish={onSubmitForm}
              onValuesChange={(value, allValue) => {
                if (
                  trimDash(allValue.identificationNumber) !==
                    trimDash(initValue.identificationNumber) ||
                  allValue.email !== initValue.email ||
                  allValue.phoneNumber !== initValue.phoneNumber
                ) {
                  setDisableSave(false);
                } else {
                  setDisableSave(true);
                }
              }}
              form={form}
              id="your-information-form"
              layout="vertical"
              style={{ marginBottom: "16px" }}
            >
              {!identificationNumber && (
                <Form.Item
                  name="identificationNumber"
                  label={getLabel("identification-number", "capitalFirstEach")}
                  rules={validateThaiCitizenIDRules}
                  required
                >
                  <MaskedInput
                    mask={[{ mask: "0-0000-00000-00-0" }]}
                    disabled={disabledIdcard}
                    placeholder={getLabel(
                      "identification-number",
                      "capitalFirstEach"
                    )}
                    suffix={
                      <div
                        className="change-text-button"
                        onClick={() => setDisableIdcard((prev) => !prev)}
                      >
                        {getLabel("change", "capitalize")}
                      </div>
                    }
                  />
                </Form.Item>
              )}
              {!phoneNumber && (
                <Form.Item
                  name="phoneNumber"
                  label={getLabel("phone-number", "capitalFirstEach")}
                  required
                  rules={[
                    {
                      pattern: /\b\w{9,10}\b/,
                      message: i18n.format(
                        getText("invaild-phonenumber"),
                        "capitalize"
                      ),
                    },
                    {
                      required: true,
                      message: i18n.format(
                        getText("is-required", {
                          key: getText("phone-number"),
                        }),
                        "capitalize"
                      ),
                    },
                  ]}
                >
                  <MaskedInput
                    mask={[{ mask: "0000000000" }]}
                    maxLength={10}
                    minLength={9}
                    disabled={disabledPhone}
                    addonBefore="+66"
                    placeholder={getLabel("phone-number", "capitalize")}
                    suffix={
                      <div
                        className="change-text-button"
                        onClick={() => setDisablePhone((prev) => !prev)}
                      >
                        {getLabel("change", "capitalize")}
                      </div>
                    }
                  />
                </Form.Item>
              )}
              <div className="w-full" style={{ margin: "12px 0 !important" }}>
                <div
                  id="save-change-button"
                  className={disabledSave ? "disabled" : ""}
                  onClick={disabledSave ? () => {} : () => form.submit()}
                >
                  {i18n.format(getText("save-change"), "uppercase")}
                </div>
              </div>
              <hr />
            </Form>
          </>
        )}
        <div id="bank-detail-label">{f(t("bank-details"), "uppercase")}</div>
        <div className="bank-name-select-container">
          <div className="link-account-topic-label" id="bank-name-label">
            {f(t("bank-name"), "capitalFirstEach")}
          </div>
          <SelectInput
            wrapClassName="verify-account-select-wrapper"
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            placeholder={f(t("select-bank-name"), "capitalFirstEach")}
            suffixIcon={<CaretDownFilled />}
            onChange={(value, option) => {
              const findBank = Object.values(bankList).find(
                (item) => item.code === value
              );
              var maxLength = 10;
              if (findBank) {
                setBankCode(findBank?.swiftCode);
                if (
                  findBank?.swiftCode &&
                  arrayBankCodeTwoDigi.includes(findBank?.swiftCode)
                ) {
                  maxLength = 12;
                }
              } else {
                setBankCode(null);
              }
              if (accountNumber && accountNumber.length > 0) {
                setAccountNumber(accountNumber.slice(0, maxLength));
              }
              setBank(value);
            }}
            value={bank}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={f(t("empty-description"), "capitalize")}
              />
            }
          >
            {Object.values(bankList).map((obj) => (
              <Option key={obj.code} value={obj.code}>
                {obj[`nice_name_${currentLang || "en"}`]}
              </Option>
            ))}
          </SelectInput>
        </div>
        <div className="account-holder-content-container">
          <div
            className="link-account-topic-label"
            id="account-holder-name-label"
          >
            {f(t("account-holder-name"), "capitalFirstEach")}
          </div>
          <TextInput
            wrapClassName="verify-account-input-wrapper"
            placeholder={f(t("account-holder-name"), "capitalFirstEach")}
            onChange={handleText}
            value={accountName}
          />
        </div>
        <div className="account-number-content-container">
          <div
            className="link-account-topic-label"
            id="account-number-name-label"
          >
            {f(t("account-number"), "capitalFirstEach")}
          </div>
          <TextInput
            wrapClassName="verify-account-input-wrapper"
            placeholder={f(t("account-number"), "capitalFirstEach")}
            onChange={onAccountNumberChange}
            value={accountNumber}
          />
        </div>
        <div className="upload-book-bank-image-container">
          <div id="upload-book-bank-label">
            {f(t("upload-your-book-bank"), "uppercase")}
          </div>
          <FileUploader
            wrapClassName="drag-and-drop-file-container"
            placeholder={t("drag-or-click-book-bank-label")}
            haveFile={notEmpty(bookBank) ? bookBank : null}
            types={fileType}
            name="book-bank-file"
            handleChange={handleBookBankImage}
            multiple={false}
          />
        </div>
        <div id="book-bank-instruction" className="instruction-container">
          <div id="instruction-book-bank-label">
            {f(t("please-take-care-of-the-following"), "capitalize")}
          </div>
          <div className="book-bank-description-detail-container">
            <InstructionDetail
              iconSrc={
                <img src="/images/account/book-bank.svg" alt="book-bank-icon" />
              }
              instructions={[
                { text: t("instruction-detail.rule-1") },
                { text: t("instruction-detail.rule-2") },
                { text: t("instruction-detail.rule-3") },
                { text: t("instruction-detail.rule-4") },
              ]}
            />
          </div>
        </div>
        <div className="upload-id-passport-image-container">
          <div id="upload-id-passport-label">
            {f(t("upload-id-passport-label"), "uppercase")}
          </div>
          <FileUploader
            wrapClassName="drag-and-drop-file-container"
            placeholder={t("drag-or-click-id-label")}
            haveFile={notEmpty(IDCard) ? IDCard : null}
            types={fileType}
            handleChange={handleIDCardImage}
            multiple={false}
          />
        </div>
        <div id="id-card-instrucition" className="instruction-container">
          <div id="instruction-id-card-label">
            {f(t("please-take-care-of-the-following"), "capitalize")}
          </div>
          <div className="id-card-description-detail-container">
            <InstructionDetail
              iconSrc={
                <div
                  className="multiple-description-wrapper"
                  style={{ marginRight: 35 }}
                >
                  <img src="/images/account/id-card.svg" alt="id-card" />
                  <img src="/images/account/passport.svg" alt="passport" />
                </div>
              }
              instructions={[
                { text: t("instruction-detail.rule-1") },
                { text: t("instruction-detail.rule-2") },
                { text: t("instruction-detail.rule-3") },
                { text: t("instruction-detail.rule-4") },
              ]}
            />
          </div>
        </div>
        <div
          className={`unselectable text-center cursor-${
            disabledSubmitButton ? "not-allowed" : "pointer"
          }`}
          id={`submit-link-bank-form-button${
            disabledSubmitButton ? "-disabled" : ""
          }`.trim()}
          onClick={(e) => {
            e.stopPropagation();
            if (!disabledSubmitButton) {
              handleSubmitBookBankDetail({
                bankNo: accountNumber,
                bankProviderId: bank,
                holderName: accountName,
                bookBankImage: bookBank,
                idCardImage: IDCard,
              });
            }
          }}
        >
          {f(t("submit"), "uppercase")}
        </div>
      </div>
    </Spin>
  );
};

export default LinkBankAccount;
