import { useContext } from "react";
import { Modal, Button, Space } from "antd";
import { ACTIONS } from "../../stateManagement/actions/commonAction";
import { MODAL_TYPE } from "../../config/customModalConfig";
import {
  CommonContext,
  CommonDispatchContext,
} from "../../stateManagement/context/commonContext";
import { isFunction } from "../../utilities/util";
import { useTranslation } from "react-i18next";
// style
import "./CustomModal.css";

const CustomModal = ({ className }) => {
  const { t: getText, i18n } = useTranslation();
  const { isShowModal, modalConfig } = useContext(CommonContext);
  const commonDispatch = useContext(CommonDispatchContext);
  const {
    type,
    callback,
    title,
    description,
    className: configClassName,
    buttonType,
    cancelType,
    buttonText,
    cancelText,
    ...otherModalProps
  } = modalConfig || {};
  const renderModalContent = () => {
    switch (type) {
      case MODAL_TYPE.PROJECT_COMPLETE:
        return (
          <div className="project-modal-content-container">
            <div className="project-modal-title">
              {i18n.format(getText("project-completed"), "capitalFirstEach")}
            </div>
            <div className="project-modal-earn">
              {i18n.format(getText("just-earn", { num: Number(description) || 0 }))}
            </div>
            <div className="project-modal-describe">
              {i18n.format(getText("complete-project-describe"))}
            </div>
          </div>
        );
      case MODAL_TYPE.DESCRIBE:
        return (
          <div className="description-modal-content-container">
            {description}
          </div>
        );
      case MODAL_TYPE.CONFIRM_SCREENING_FORM:
        return (
          <>
            <div className="screening-modal-content-container">
              <div className="screening-modal-title">
                {i18n.format(
                  getText("save-screening-completed"),
                  "capitalFirstEach"
                )}
              </div>
              <div className="screening-modal-describe">
                {i18n.format(
                  getText("save-screening-describe", {
                    projectName: description,
                  })
                )}
              </div>
            </div>
            <Button
              type={buttonType || "success"}
              onClick={() => {
                if (isFunction(callback)) {
                  callback();
                }
              }}
            >
              {buttonText || i18n.format(getText("i-got-it"), "capitalize")}
            </Button>
          </>
        );
      case MODAL_TYPE.CONFIRM:
        const buttonContainer = (
          <Space
            direction="vertical"
            className="w-full"
            style={{ marginTop: 20 }}
          >
            <Button
              type={buttonType || "success"}
              onClick={() => {
                if (isFunction(callback)) {
                  callback();
                }
              }}
            >
              {buttonText || i18n.format(getText("confirm"), "capitalize")}
            </Button>
            <Button
              className="cancel-button"
              type={cancelType || "default"}
              onClick={() => {
                commonDispatch({ type: ACTIONS.HIDE_CUSTOM_MODAL });
              }}
            >
              {cancelText || i18n.format(getText("cancel"), "capitalize")}
            </Button>
          </Space>
        );

        if (!title) {
          return (
            <div className="confirm-modal-content-container">
              <div className="confirm-modal-description w-full flex-1 flex justify-center items-center">
                {description}
              </div>
              <div className="w-full">{buttonContainer}</div>
            </div>
          );
        }

        return (
          <div className="confirm-modal-content-container">
            <div className="confirm-modal-title">{title}</div>
            <div className="w-full">
              <div className="confirm-modal-description">{description}</div>
              {buttonContainer}
            </div>
          </div>
        );
      default:
    }
  };

  return (
    <Modal
      className={`custom-modal ${className}`}
      open={isShowModal}
      onCancel={() => commonDispatch({ type: ACTIONS.HIDE_CUSTOM_MODAL })}
      footer={null}
      closeIcon={null}
      destroyOnClose
    >
      <div className="custom-modal-content-container">
        {renderModalContent()}
        {[MODAL_TYPE.PROJECT_COMPLETE, MODAL_TYPE.DESCRIBE].includes(type) && (
          <Button
            type={type === MODAL_TYPE.PROJECT_COMPLETE ? "success" : buttonType}
            onClick={() => {
              if (isFunction(callback)) {
                callback();
              }
            }}
          >
            {buttonText}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default CustomModal;
