const ACTIONS = {
  SET_NOTIFICATION: "set-notification",
  CLEAR_NOTIFICATION: "clear-notification",
  SET_CURRENT_USER: "set-current-user",
  REMOVE_CURRENT_USER: "remove-current-user",
  SET_RECENT_PROJECT: "set-recent-project",
  SHOW_CUSTOM_MODAL: "show-custom-modal",
  HIDE_CUSTOM_MODAL: "hide-custom-modal",
  SET_COMMON_NAV_TITLE: "set-common-nav-title",
  SHOW_GLOBAL_LOADING: "show-global-loading",
  HIDE_GLOBAL_LOADING: "hide-global-loading",
  SET_REWARD_SELECTED: "set-reward-selected",
  FIND_PROJECT_SCREENING: "find-project-screening",
  FIND_PARTNER_CODE: "find-partner-code",
};

export { ACTIONS };
