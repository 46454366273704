import React from 'react';
import './instructionDetail.css';

const InstructionDetail = (props) => {
  const { iconSrc, instructions } = props || {};
  const instructionElement = (o) => (
    <div className="flex items-start instruction-row">
      <div className="instruction-rule-icon-container">
        <img src="/icon/check-icon.svg" alt="check-icon" />
      </div>
      <div className="instruction-detail-label">{o.text}</div>
    </div>
  )
  return (
    <div className="flex justify-between items-start instruction-detail-container">
      <div className="instruction-icon-container">
        {iconSrc || null}
      </div>
      <div className="instruction-detail-list-container">
        {(instructions || []).map(instructionElement)}
      </div>
    </div>
  )
}

export default InstructionDetail;