import React from "react";
import { Input } from "antd";

const TextInputWrapper = ({ wrapClassName, wrapID, ...otherProps }) => {
  return (
    <div className={wrapClassName} id={wrapID}>
      <Input {...otherProps} />
    </div>
  );
};

export default TextInputWrapper;
