import { ROUTE } from "../config/common";
const getNavText = (path) => {
  if (path.includes(ROUTE.ACCOUNT.PERSONAL_DATA))
    return {
      text: "personal-and-information",
      format: "capitalize",
      backRoute: `/${ROUTE.ACCOUNT.ROOT}`,
    };
  if (path.includes(ROUTE.ACCOUNT.PASSWORD))
    return {
      text: "password-and-security",
      format: "capitalize",
      backRoute: `/${ROUTE.ACCOUNT.ROOT}`,
    };
  if (path.includes(ROUTE.ACCOUNT.CASH_DETAIL.LINK_BANK_ACCOUNT))
    return {
      text: "verify-account",
      format: "capitalFirstEach",
      backRoute: `${ROUTE.ACCOUNT.MAIN_PAGE}/${ROUTE.ACCOUNT.CASH_DETAIL.ROOT}`,
    };
  if (path.includes(ROUTE.ACCOUNT.CASH_DETAIL.EDIT_VERIFY_ACCOUNT))
    return {
      text: "verify-account",
      format: "capitalFirstEach",
      backRoute: `${ROUTE.ACCOUNT.MAIN_PAGE}/${ROUTE.ACCOUNT.CASH_DETAIL.ROOT}`,
    };
  if (path.includes(ROUTE.ACCOUNT.CASH_DETAIL.CASH_OUT_HISTORY))
    return {
      text: "cash-out-history",
      format: "capitalFirstEach",
      backRoute: `${ROUTE.ACCOUNT.MAIN_PAGE}/${ROUTE.ACCOUNT.CASH_DETAIL.ROOT}`,
    };
  if (path.includes(ROUTE.ACCOUNT.CASH_DETAIL.CASH_OUT))
    return {
      text: "cash-out",
      format: "capitalFirstEach",
      backRoute: `${ROUTE.ACCOUNT.MAIN_PAGE}/${ROUTE.ACCOUNT.CASH_DETAIL.ROOT}`,
    };
  if (path.includes(ROUTE.ACCOUNT.CASH_DETAIL.ROOT))
    return {
      text: "cash-details",
      format: "capitalize",
      backRoute: `/${ROUTE.ACCOUNT.ROOT}`,
    };
  if (path.includes(ROUTE.ACCOUNT.MY_EARNING))
    return {
      text: "my-earning",
      format: "capitalFirstEach",
      backRoute: `/${ROUTE.ACCOUNT.ROOT}`,
    };
  if (path.includes(ROUTE.ACCOUNT.LANGUAGE))
    return {
      text: "settings",
      format: "capitalize",
      backRoute: `/${ROUTE.ACCOUNT.ROOT}`,
    };
  if (path.includes(ROUTE.ACCOUNT.VERIFY_ACCOUNT))
    return {
      text: "verify-account",
      format: "capitalFirstEach",
      backRoute: `/${ROUTE.ACCOUNT.ROOT}`,
    };
  return {
    text: "account-and-settings",
    format: "uppercase",
    backRoute: ROUTE.ROOT,
  };
};

const getRequiredText = (getText, i18n, key) => {
  return i18n.format(
    getText("is-required", { key: getText(key) }),
    "capitalize"
  );
};

const displayTextAmount = (val) => {
  return Number(val).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export { getNavText, getRequiredText, displayTextAmount };
