import { useCallback, useContext, useEffect, useState } from "react";
import { CommonDispatchContext } from "../stateManagement/context/commonContext";
import { ACTIONS } from "../stateManagement/actions/commonAction";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { Badge, Avatar, Input, Form, Modal, Space, Button, Spin } from "antd";
import {
  AccountContextProvider,
  AccountContext,
} from "../stateManagement/context/accountContext";
// import compoent
import PersonalDataNotice from "../components/account/PersonalDataNotice";
import { Responsive } from "../utilities/Responsive/responsive";
import { CommonNavbar } from "../components/common/Navbar";
import { getNavText, getRequiredText } from "../utilities/textUtil";
import UserDetail from "../components/account/UserDetail";
import YourInformation from "../components/account/YourInformation";
import ChangeLanguage from "../components/common/ChangeLang";
import MyEarning from "../components/account/MyEarning";
import CashDetail from "../components/account/CashDetail";
import LinkBankAccount from "../components/account/LinkBankAccount";
import CashOut from "../components/account/CashOut";
import { CommonContext } from "../stateManagement/context/commonContext";
import { clearToken } from "../utilities/authenUtil";
// import style
import "../styles/account.css";
// import config
import { DEVICE, ROUTE, PASSWORD_REGEX } from "../config/common";
import { useTranslation } from "react-i18next";
import CashOutHistory from "../components/account/CashOutHistory";
// import for api
import { useLazyQuery, useMutation } from "@apollo/client";
import { CHANGE_PASSWORD } from "../graphql/mutation";
import { LOGOUT, PROFILE } from "../graphql/query";
import { isEmpty, notEmpty } from "../utilities/util";

const { version } = require("../../package.json");

const logoutCallback = () => {
  clearToken();
  if (process.env.REACT_APP_LANDING_PAGE) {
    window.location.replace(process.env.REACT_APP_LANDING_PAGE);
  } else {
    window.location.replace(ROUTE.UNAUTHEN);
  }
};

const InformataText = () => {
  return (
    <div id="informata-text-container">
      <div id="informata-text-title">© Informata 2022</div>
      <div id="informata-description">
        Informatar is Online Projects Suite account with the appropriate
        permission is required.
      </div>
      <div id="informata-description">Version : v{version}</div>
    </div>
  );
};

const MobileMenuButton = ({ title, link }) => {
  const navigate = useNavigate();
  return (
    <div
      className="account-menu-button"
      onClick={() => {
        navigate(link);
      }}
    >
      <div>{title}</div>
      <img
        className="account-menu-arrow"
        src="/images/common/chevron-left.svg"
        alt=""
        height={14}
      />
    </div>
  );
};

export const AccountMobileHomeScreen = () => {
  const { t: getText, i18n } = useTranslation();
  return (
    <div id="account-home-wrapper" className="full-height">
      <div>
        <div className="account-text-header" style={{ marginBottom: 16 }}>
          {i18n.format(getText("account"), "capitalize")}
        </div>
        <PersonalDataNotice />
        <div style={{ marginTop: 17, marginBottom: 30 }}>
          <MobileMenuButton
            title={i18n.format(
              getText("personal-and-information"),
              "capitalize"
            )}
            link={ROUTE.ACCOUNT.PERSONAL_DATA}
          />
          <MobileMenuButton
            title={i18n.format(getText("password-and-security"), "capitalize")}
            link={ROUTE.ACCOUNT.PASSWORD}
          />
          <MobileMenuButton
            title={i18n.format(getText("cash-details"), "capitalize")}
            link={ROUTE.ACCOUNT.CASH_DETAIL.ROOT}
          />
          <MobileMenuButton
            title={i18n.format(getText("my-earning"), "capitalize")}
            link={ROUTE.ACCOUNT.MY_EARNING}
          />
        </div>
        <div className="account-text-header" style={{ marginBottom: 10 }}>
          {i18n.format(getText("setting"), "capitalize")}
        </div>
        <MobileMenuButton
          title={i18n.format(getText("display-language"), "capitalize")}
          link={ROUTE.ACCOUNT.LANGUAGE}
        />
      </div>
      <InformataText />
    </div>
  );
};

export const AccountMobilePernalDataScreen = () => {
  const { currentUser } = useContext(CommonContext);
  const { fullname, uid } = currentUser || {};

  return (
    <div id="account-personal-data-container">
      <UserDetail title={fullname} role="Participant" uid={uid} />
      <YourInformation isDesktop={false} currentUser={currentUser} />
    </div>
  );
};

const ChangePasswordModal = ({ onCancel }) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const getText = (text, format) => i18n.format(t(text), format);
  const [changePassword, { data, loading }] = useMutation(CHANGE_PASSWORD);
  const onSubmitPassword = (value) => {
    const { password: oldPassword, newPassword } = value;
    changePassword({ variables: { input: { oldPassword, newPassword } } });
  };

  const onPasswordChange = (e) => {
    const currentValue = e?.target?.value;
    const confirmValue = form.getFieldValue("confirmNewPassword");
    const { errors } = form.getFieldsError(["confirmNewPassword"])?.[0] || {};
    if (notEmpty(errors) && confirmValue && currentValue === confirmValue) {
      form.setFields([
        {
          name: "confirmNewPassword",
          errors: [],
        },
      ]);
    } else if (isEmpty(errors) && confirmValue) {
      form.setFields([
        {
          name: "confirmNewPassword",
          errors: [getText("password-confirm-not-match")],
        },
      ]);
    }
  };

  if (data) {
    return (
      <div className="flex flex-col justify-between items-center h-full pt-10">
        <div className="flex flex-col justify-center items-center">
          <Badge
            offset={[-10, 10]}
            className="change-password-success-icon-badge"
            count={
              <div id="check-icon">
                <img src="/images/common/check-icon-white.svg" alt="" />
              </div>
            }
            style={{ backgroundColor: "var(--primary-green)" }}
          >
            <div id="change-password-success-icon">
              <img
                width={42}
                height={45}
                src="/images/common/lock-filled.svg"
                alt=""
              />
            </div>
          </Badge>
          <div className="flex flex-col justify-center items-center">
            <div
              className="primary-blue font-bold text-2xl text-center mt-5 mb-16"
              style={{ width: 310 }}
            >
              {getText("changed-password-title")}
            </div>
          </div>
        </div>
        <Button
          type="primary"
          onClick={() => {
            onCancel();
            clearToken();
            window.location.replace(
              process.env.REACT_APP_LANDING_PAGE
                ? `${
                    process.env.REACT_APP_LANDING_PAGE
                  }&error=unauthen&redirect=${window?.location?.href || ""}`
                : ROUTE.UNAUTHEN
            );
          }}
        >
          {getText("got-it")}
        </Button>
      </div>
    );
  }

  return (
    <Spin className="h-full" spinning={loading}>
      <div id="change-password-content-modal">
        <Form form={form} onFinish={onSubmitPassword}>
          <div className="flex items-center gap-3 mb-7">
            <Avatar
              size={40}
              className="change-password-icon"
              src={<img src="/images/common/lock-filled.svg" alt="" />}
            />
            <div className="font-bold text-2xl darkgray">
              {getText("change-password", "capitalFirstEach")}
            </div>
          </div>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: getRequiredText(t, i18n, "current-password"),
              },
            ]}
          >
            <Input.Password
              placeholder={getText("current-password", "capitalFirstEach")}
            />
          </Form.Item>
          <Form.Item
            name="newPassword"
            rules={[
              {
                required: true,
                message: getRequiredText(t, i18n, "new-password"),
              },
            ]}
          >
            <Input.Password
              placeholder={getText("new-password", "capitalFirstEach")}
              onChange={onPasswordChange}
            />
          </Form.Item>
          <Form.Item
            name="confirmNewPassword"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.reject(
                      new Error(getRequiredText(t, i18n, "retype-new-password"))
                    );
                  }
                  if (!PASSWORD_REGEX.test(value)) {
                    return Promise.reject(
                      new Error(getText("password-wrong-format"))
                    );
                  }
                  if (getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(getText("password-confirm-not-match"))
                  );
                },
              }),
            ]}
          >
            <Input.Password
              placeholder={getText("retype-new-password", "capitalFirstEach")}
            />
          </Form.Item>
        </Form>
        <div id="change-password-button-container">
          <Button id="save-button" type="primary" onClick={() => form.submit()}>
            {getText("save", "capitalize")}
          </Button>
          <Button id="cancel-button" onClick={() => onCancel()}>
            {getText("cancel", "capitalize")}
          </Button>
        </div>
      </div>
    </Spin>
  );
};

const AccountScreenDesktop = () => {
  const { currentUser } = useContext(CommonContext);
  const { fullname, email, phoneNumber: phoneNo, uid } = currentUser || {};
  const [showModal, setShowModal] = useState(false);
  const [language, setLanguage] = useState(null);
  const dispatch = useContext(CommonDispatchContext);

  const { t: getText, i18n } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const accountData = useContext(AccountContext);
  const [logout] = useLazyQuery(LOGOUT, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      logoutCallback();
    },
  });

  useEffect(() => {
    if(currentUser) {
      setLanguage(currentUser?.language === 'THAI' ? "TH" : "EN")
    }
  },[currentUser])

  const [getProfile] = useLazyQuery(PROFILE, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setLanguage(data?.profile?.language)
      dispatch({
        type: ACTIONS.SET_CURRENT_USER,
        currentUser: {
          ...data?.profile,
          fullname: `${
            data?.profile?.firstname ? data?.profile?.firstname : ""
          } ${data?.profile?.middlename ? data?.profile?.middlename : ""} ${
            data?.profile?.lastname ? data?.profile?.lastname : ""
          }`,
        },
      });
    },
  });

  useEffect(() => {
    if (pathname !== ROUTE.ACCOUNT.ROOT) {
      navigate(ROUTE.ACCOUNT.MAIN_PAGE, { replace: true });
    }
  }, []);
  const { viewScreen } = accountData;

  const handleCashoutSuccess = useCallback((slug) => {
    if (slug) {
      getProfile();
    }
  }, []);

  return (
    <>
      <Modal
        destroyOnClose
        wrapClassName="change-password-modal-container"
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
      >
        <ChangePasswordModal onCancel={() => setShowModal(false)} />
      </Modal>
      <div className="account-content-container desktop">
        <div className="account-pane left-pane">
          <div id="user-detail-wrapper">
            <UserDetail title={fullname} role="Participant" uid={uid} />
            <div id="user-detail-action-container">
              <div
                className="account-text-header"
                style={{ color: "var(--title-color)" }}
              >
                {i18n.format(getText("your-details"), "uppercase")}
              </div>
              <div id="user-mail-phone-container">
                <div>{email}</div>
                <div>{phoneNo ? `+66 ${phoneNo}` : "-"}</div>
              </div>
              <Space
                id="user-detail-action-button-container"
                className="w-full"
                direction="vertical"
              >
                <Button
                  id="change-password-button"
                  className="w-full"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  {i18n.format(getText("change-password"), "capitalFirstEach")}
                </Button>
                <Button id="logout-button" className="w-full" onClick={logout}>
                  {i18n.format(getText("logout"), "capitalFirstEach")}
                </Button>
              </Space>
            </div>
          </div>
          <InformataText />
        </div>
        <div className="account-pane center-pane">
          <PersonalDataNotice />
          <CashDetail />
          <MyEarning />
          <YourInformation isDesktop={true} currentUser={currentUser} />
          <ChangeLanguage setLanguage={setLanguage} />
        </div>
        <div className="account-pane right-pane">
          {["link-bank-account", "verify"].includes(viewScreen) && (
            <LinkBankAccount lang={language ? language.toUpperCase() : 'EN'} />
          )}
          {["cash-out"].includes(viewScreen) && (
            <CashOut onFinish={handleCashoutSuccess} />
          )}
          {["cash-out-history"].includes(viewScreen) && <CashOutHistory />}
        </div>
      </div>
    </>
  );
};

const AccountScreenMobile = () => {
  const dispatch = useContext(CommonDispatchContext);
  const { pathname } = useLocation();
  useEffect(() => {
    const navText = getNavText(pathname);
    dispatch({
      type: ACTIONS.SET_COMMON_NAV_TITLE,
      commonNavTitle: navText,
    });
  }, [pathname]);
  return (
    <div className="account-content-container mobile">
      <CommonNavbar />
      <div className="account-content-body">
        <Outlet />
      </div>
    </div>
  );
};

const AccountScreen = () => {
  return (
    <div id="account-content-wrapper">
      <AccountContextProvider>
        <Responsive displayIn={[DEVICE.LAPTOP]}>
          <AccountScreenDesktop />
        </Responsive>
        <Responsive displayIn={[DEVICE.MOBILE, DEVICE.TABLET]}>
          <AccountScreenMobile />
        </Responsive>
      </AccountContextProvider>
    </div>
  );
};

export default AccountScreen;
