import { Result } from "antd";
import { useTranslation } from "react-i18next";
const UnAuthenScreen = () => {
  const { t: getText, i18n } = useTranslation();
  return (
    <Result
      status="error"
      title={i18n.format(getText("unauthen-title"))}
      subTitle={i18n.format(getText("unauthen-description"))}
    />
  );
};

export default UnAuthenScreen;
