import React from 'react';
import './CashOutTips.css';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const CashOutTips = () => {
  const { t } = useTranslation();
  const { format: f } = i18n;
  return (
    <div className="cash-out-tips-container flex items-center">
      <div className="tips-image-container">
        <img src="/images/account/tips.svg" alt="tips" />
      </div>
      <div className="cash-out-tip-topic-container">
        <div className="cash-out-label-header">
          {f(t('cash-out-tips'), 'capitalFirstEach')}
        </div>
        <div className="cash-out-description">
          {f(t('cash-out-description'), 'uppercase')}
        </div>
      </div>
    </div>
  )
}

export default CashOutTips;