// for gql mutation config
import { gql } from "@apollo/client";

const START_SURVEY = gql`
  mutation startProjectSurvey($projectUid: String!) {
    takeStudy(projectUid: $projectUid) {
      messageCode
      message
    }
  }
`;

const RESUME_SURVEY = gql`
  mutation resumeProjectSurvey($projectUid: String!, $studyUid: String!) {
    resumeStudy(projectUid: $projectUid, studyUid: $studyUid) {
      messageCode
      message
    }
  }
`;

const RETAKE_SURVEY = gql`
  mutation retakeProjectSurvey($projectUid: String!, $studyUid: String!) {
    retakeStudy(projectUid: $projectUid, studyUid: $studyUid) {
      messageCode
      message
    }
  }
`;

const QUIT_SURVEY = gql`
  mutation quitProjectSurvey($projectUid: String!) {
    quitStudy(projectUid: $projectUid) {
      messageCode
      message
    }
  }
`;

const UPDATE_PROFILE = gql`
  mutation updateProfile($input: AccountUpdateContactInput!) {
    updateContactAccount(input: $input) {
      message
      messageCode
    }
  }
`;

const UPDATE_LANGUAGE = gql`
  mutation updateLanguage($input: AccountUpdateLanguageInput!) {
    updateAccountLanguage(input: $input) {
      message
      messageCode
    }
  }
`;

const UPDATE_SCREENING = gql`
  mutation updateQuestion(
    $input: [ScreeningAnswerUpdateInput!]!
    $typeUid: String
  ) {
    updateScreeningAnswer(input: $input, typeUid: $typeUid) {
      message
    }
  }
`;
const CHANGE_PASSWORD = gql`
  mutation changePassword($input: AccountChangePasswordInput!) {
    changePassword(input: $input) {
      message
      messageCode
    }
  }
`;

const SEND_VERIFICATION = gql`
  mutation createBankVerification(
    $input: BankVerificationCreateInput!
    $bookBankImage: Upload
    $idCardImage: Upload
  ) {
    createBankVerification(
      input: $input
      bookBankImage: $bookBankImage
      idCardImage: $idCardImage
    ) {
      messageCode
      message
    }
  }
`;

const CASH_OUT_WITHDRAW = gql`
  mutation cashOutWithdraw($input: ICashOutWithdraw!) {
    cashOutWithdraw(input: $input) {
      messageCode
      message
    }
  }
`;

const UPLOAD_PROFILE_IMAGE = gql`
  mutation uploadProfilePic($profileImage: Upload!) {
    uploadProfilePic(profileImage: $profileImage) {
      messageCode
      message
    }
  }
`;

const RESEND_CHANGE_EMAIL = gql`
  mutation resendChangeEmail {
    resendChangeEmail {
      messageCode
      message
    }
  }
`;

const CANCEL_CHANGE_EMAIL = gql`
  mutation cancelChangeEmail {
    cancelChangeEmail {
      messageCode
      message
    }
  }
`;

const UPDATE_READ_FLAG = gql`
  mutation updateFlagReadActive($notificationUid: String!) {
    updateFlagReadActive(notificationUid: $notificationUid) {
      messageCode
      message
    }
  }
`;
const CREATE_REDEEM = gql`
  mutation CreateRedeem($input: RedeemCreateInput!) {
    createRedeem(input: $input) {
      messageCode
      message
    }
  }
`;

const UPDATE_READ_FLAG_ALL = gql`
  mutation UpdateFlagReadAllActive {
    updateFlagReadAllActive {
      message
      messageCode
    }
  }
`;
const DELETE_NOTIFICATION_ALL = gql`
  mutation deleteNotificationAll {
    deleteNotificationAll {
      message
      messageCode
    }
  }
`;

export {
  START_SURVEY,
  RESUME_SURVEY,
  RETAKE_SURVEY,
  QUIT_SURVEY,
  UPDATE_PROFILE,
  UPDATE_SCREENING,
  CHANGE_PASSWORD,
  UPDATE_LANGUAGE,
  SEND_VERIFICATION,
  CASH_OUT_WITHDRAW,
  UPLOAD_PROFILE_IMAGE,
  RESEND_CHANGE_EMAIL,
  CANCEL_CHANGE_EMAIL,
  UPDATE_READ_FLAG,
  CREATE_REDEEM,
  UPDATE_READ_FLAG_ALL,
  DELETE_NOTIFICATION_ALL,
};
