import { ACTIONS } from "../actions/accountAction";

const accountReducer = (state, { type, ...action }) => {
  switch (type) {
    case ACTIONS.APPEND_KEY: {
      return { ...state, [action.key]: action.value };
    }
    default:
      return state;
  }
};

export default accountReducer;
