const setNotification = (oldState, payload) => {
  const { notifications: newNoti } = payload;
  return {
    ...oldState,
    notifications: newNoti,
  };
};

const clearNotification = (oldState) => {
  return {
    ...oldState,
    notifications: 0,
  };
};

const setRecentProject = (oldState, payload) => {
  const { recentProjectID: newRecent } = payload;
  return {
    ...oldState,
    recentProjectID: newRecent,
  };
};

const showModal = (oldState, payload) => {
  const { modalConfig: newModalConfig } = payload;
  return {
    ...oldState,
    isShowModal: true,
    modalConfig: newModalConfig,
  };
};
const hideModal = (oldState) => {
  return {
    ...oldState,
    isShowModal: false,
    modalConfig: null,
  };
};

const setCommonNavTitle = (oldState, payload) => {
  const { commonNavTitle: newTitle } = payload;
  return {
    ...oldState,
    commonNavTitle: newTitle,
  };
};

const setCurrentUser = (oldState, payload) => {
  const { currentUser: newUserDetail } = payload;
  return {
    ...oldState,
    currentUser: newUserDetail,
  };
};

const showGlobalLoading = (oldState, payload) => {
  return {
    ...oldState,
    globalLoading: true,
  };
};

const hideGlobalLoading = (oldState, payload) => {
  return {
    ...oldState,
    globalLoading: false,
  };
};
const setRewardSelected = (oldState, payload) => {
  return {
    ...oldState,
    rewardSeleted: payload?.searchCodeReward,
  };
};
const findProject = (oldState, payload) => {
  return {
    ...oldState,
    findProject: payload?.data,
  };
};
const findPartnerCode = (oldState, payload) => {
  const { isCode, code, choiceUid } = payload?.data;
  return {
    ...oldState,
    findPartnerCode: { isCode: isCode, code: code, choiceUid: choiceUid },
  };
};

export {
  setNotification,
  clearNotification,
  setRecentProject,
  showModal,
  hideModal,
  setCommonNavTitle,
  setCurrentUser,
  showGlobalLoading,
  hideGlobalLoading,
  setRewardSelected,
  findProject,
  findPartnerCode,
};
