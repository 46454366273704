import { createContext, useReducer } from "react";
import CommmonReducer from "../reducers/commonReducer";
const initState = {
  notifications: 0,
  // recentProjectID: 1,
  recentProjectID: null,
  isShowModal: false,
  modalConfig: null,
  commonNavTitle: {},
  currentUser: {},
  globalLoading: true,
  rewardSeleted: null,
  findProject: {},
  findPartnerCode: { isCode: false, code: null },
};

const CommonContext = createContext();
const CommonDispatchContext = createContext();

const CommonProvider = ({ children }) => {
  const [value, dispatch] = useReducer(CommmonReducer, initState);
  return (
    <CommonContext.Provider value={value}>
      <CommonDispatchContext.Provider value={dispatch}>
        {children}
      </CommonDispatchContext.Provider>
    </CommonContext.Provider>
  );
};

export { CommonContext, CommonDispatchContext, CommonProvider };
