import time from "../../utilities/time";
const toFrontendData = (data = {}) => {
  const { withdrawTransaction } = data;
  const { data: list } = withdrawTransaction || {};
  return list.map((row) => {
    return {
      id: row.uid,
      timeStamp: time.new(row.updatedAt).valueOf(),
      amount: row.transactionAmount,
      transactionFee: (row.transactionFee * -1).toFixed(2),
      wht: (row.transactionWHT * -1).toFixed(2),
      status: row?.transactionStatus || "",
    };
  });
};

export default toFrontendData;
