import { useTranslation } from "react-i18next";
import "./MyEarning.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useQuery } from "@apollo/client";
import { GET_MY_EARNING } from "../../graphql/query";
import { Spin } from "antd";
import { useState } from "react";

const MyEarning = () => {
  const { t: getText, i18n } = useTranslation();
  const [dataGraph, setDataGraph] = useState();

  const transformMonthLang = (data) => {
    const newData = [];
    data.map((item) => {
      newData.push({
        name: getText(`month.${item.name}`),
        baht: item.baht ?? 0,
        icredit: item.icredit ?? 0,
      });
    });
    return newData;
  };

  const { loading } = useQuery(GET_MY_EARNING, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setDataGraph(transformMonthLang(data?.myEarning || []));
    },
  });

  // const dataGraph = transformMonthLang(
  //   getText,
  //   myEarningDataObj?.myEarning || []
  // );

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label}`}</p>
          <p className="label-value">
            {`${getText("tooltip-value")}`} :{` `}
            {`${payload[0].value}`}
          </p>
          <p className="label-credit">
            {`${getText("tooltip-credit")}`} :{` `}
            {`${payload[1].value}`}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <div id="my-earning-container">
      <Spin spinning={loading}>
        <div id="my-earning-title">
          {i18n.format(getText("my-earning"), "capitalFirstEach")}
        </div>
        <ResponsiveContainer width="100%" height={220}>
          <BarChart
            data={dataGraph}
            margin={{
              left: 0,
              right: 10,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              interval={0}
              tickLine={false}
              axisLine={false}
              dataKey="name"
            />
            <YAxis width={30} tickLine={false} axisLine={false} />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="baht" fill="var(--primary-blue)" barSize={10} />
            <Bar dataKey="icredit" fill="var(--primary-orange)" barSize={10} />
          </BarChart>
        </ResponsiveContainer>
      </Spin>
    </div>
  );
};

export default MyEarning;
